import { useQuery } from "@tanstack/react-query";
import { RQ_ORDERS_ADDRESS } from "../key";

import axios from "axios";
import { IProps as OrderIProps, OrderItem } from "./getGetOrders";
export const Events = {
  1: {
    label: "Listed",
    color: "#F0ECE6",
    value: 1,
  },
  2: {
    label: "Canceled",
    color: "rgba(240,236,230,0.6)",
    value: 2,
  },
  3: {
    label: "Pending",
    color: "#F0ECE6",
    value: 3,
  },
  4: {
    label: "Sold",
    color: "#30FFEC",
    value: 4,
  },
  5: {
    label: "Bought",
    color: "#AAFF5D",
    value: 5,
  },
  6: {
    label: "Fail",
    color: "#F0ECE6",
    value: 6,
  },
  9: {
    label: "Offered",
    color: "#F0ECE6",
    value: 9,
  },
  10: {
    label: "Withdrawed",
    color: "#F0ECE6",
    value: 10,
  },
};
interface IProps extends OrderIProps {
  address: string;
}

interface Response {
  orders: OrderItem[];
  total: number;
}
const useAddressOrders = ({
  address,
  ticker,
  status,
  page,
  page_size,
  order_by = "unit_price",
  dir = "asc",
}: IProps) => {
  const result = useQuery<Response>({
    queryKey: [
      RQ_ORDERS_ADDRESS,
      address,
      ticker,
      status,
      page,
      page_size,
      order_by,
      dir,
    ],
    queryFn: () =>
      axios
        .get(`/market/v1/addresses/${address}/orders`, {
          params: {
            ticker,
            status,
            page,
            page_size,
            order_by,
            dir,
          },
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data),
    enabled: !!address,
  });
  return result;
};
export default useAddressOrders;
