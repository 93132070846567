import { Fragment, ReactNode } from "react";
import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";
import { closeIcon, notificationIcon } from "@/assets";
interface IProps {
  children: ReactNode;
  show: boolean;
  closeModal?: (status: boolean) => void;
}
const Toast = ({ children, show, closeModal = () => {} }: IProps) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="scale-95"
              enterTo="scale-100"
              leave="ease-in duration-200"
              leaveFrom="scale-100"
              leaveTo="scale-95"
            >
              <div className="w-full px-4 max-w-md transform overflow-hidden">
                <Dialog.Panel
                  className="w-full rounded-xl bg-[rgba(0,0,0,.6)] p-4 py-8 text-white text-sm leading-5 relative"
                  style={{
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Dialog.Title as="h3">
                    <Image
                      className="cursor-pointer absolute right-5 top-5 z-50"
                      height={14}
                      src={closeIcon}
                      onClick={() => closeModal(false)}
                      alt=""
                    />
                  </Dialog.Title>
                  <Image
                    width={56}
                    className="mx-auto mb-4"
                    src={notificationIcon}
                    alt=""
                  />
                  {children}
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Toast;
