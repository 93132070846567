import { useQuery } from "@tanstack/react-query";
import { RQ_ORDERS_FEES } from "../key";
import axios from "axios";
interface IProps {
  ticker: string;
  address: string;
}
const useOrdersFees = ({ ticker, address }: IProps) => {
  const result = useQuery<{
    seller: number;
    buyer: number;
  }>({
    queryKey: [RQ_ORDERS_FEES, ticker, address],
    queryFn: () =>
      axios
        .get("/market/v1/orders/fees", {
          params: {
            ticker,
            address,
          },
        })
        .then((res) => res.data)
        .catch(() => {
          return 0;
        }),
    enabled: !!ticker && !!address,
  });
  return result;
};
export default useOrdersFees;
