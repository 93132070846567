import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { Psbt, Transaction, networks } from "bitcoinjs-lib";
import { Dialog, Transition } from "@headlessui/react";
import { Select, Button, Range, Speed, Toast } from "@/components";
import {
  useUsdPrice,
  useCoinsInfo,
  useAddressFua,
  useCoinsList,
} from "@/api/query";
import useGetAccounts from "@/hooks/wallet/useGetAccounts";
import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import { envNetwork } from "@/plugins/config";
import {
  countDecimalPlaces,
  formatAddress,
  formatNumber,
  satoshisToAmount,
  validateAddress,
} from "@/plugins/utils";
import { closeIcon, buckIcon } from "@/assets";
import { MarketContext } from "@/app/Context";
import { useGetPsbt, useOrders } from "@/api/mutation";
interface IProps {
  show: boolean;
  token: string;
  closeModal?: (status: boolean) => void;
}
const max = 100;
const MakeOffer = ({ show, token = "", closeModal = () => {} }: IProps) => {
  const [form, setForm] = useImmer({
    fee: {
      value: 0,
      index: 2,
    },
    num: "",
    receiveAddress: "",
    uintPrice: "",
    isPack: false,
    loading: false,
    token,
  });
  const [toastType, settoastType] = useState(0);
  const { wallet } = useWalletInstance();
  const { connectedWallet, wallet: walletContext } = useContext(MarketContext);
  const { mutateAsync: getPsbt, isPending } = useGetPsbt();
  const { mutateAsync: changeOrders, isPending: makePedding } = useOrders();
  const { accounts } = useGetAccounts();
  const { data: usdPrice = 0 } = useUsdPrice();
  const { data: coinsInfo } = useCoinsInfo({ id: form.token });
  const { data: listingAddress } = useAddressFua({
    type: "listing",
    address: accounts[0],
    enabled: !!accounts[0] && show,
  });
  const { data: tokenList } = useCoinsList({
    page: 1,
    page_size: 10,
    window_period: "24h",
    dir: "desc",
    sorted_by: "trading_volume",
    localSort: true,
  });
  const totalUtxos = useMemo(() => {
    return Number(form.num) * Number(coinsInfo?.data.mint_amount) || 0;
  }, [form.num, coinsInfo?.data.mint_amount]);
  const averagePrice = useMemo(() => {
    const len = countDecimalPlaces(coinsInfo?.data?.price || 0);
    return `${coinsInfo?.data?.price.toFixed(len ? 2 : 0)}` || "0";
  }, [coinsInfo]);
  const addressOptions = useMemo(() => {
    const addressList = listingAddress?.addresses || [];
    if (addressList.length) {
      return addressList;
    }
    if (accounts.length) {
      return [{ V1: accounts[0], V2: 1 }];
    }
    return [];
  }, [listingAddress]);

  const handleSignPsbt = async (hex: string) => {
    const psbt = Psbt.fromBase64(hex, {
      network: envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
    });
    const publicKey = await wallet?.getPublicKey();
    try {
      const signPsbt = await wallet?.signPsbt(hex, {
        autoFinalized: false,
        toSignInputs: psbt.data.inputs.map((item, index) => ({
          index,
          address: accounts[0],
          publicKey: publicKey,
          sighashTypes: [Transaction.SIGHASH_ALL],
        })),
      });
      if (signPsbt) {
        const basePsbt = Psbt.fromHex(signPsbt, {
          network:
            envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
        });
        return basePsbt.toBase64();
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const getSellerPsbt = async () => {
    try {
      const { psbt, sign_flags: signFlags } = await getPsbt({
        action: "offer",
        orders: [
          {
            ticker: form.token,
            unit_price: Number(form.uintPrice),
            amount: Number(coinsInfo?.data.mint_amount) * Number(form.num),
            buyer_input_address: accounts[0],
            buyer_output_address: form.receiveAddress || accounts[0],
          },
        ],
        gas_fee: form.fee.value,
      });
      const signPsbt = await handleSignPsbt(psbt);
      return { signPsbt, signFlags };
    } catch (error: any) {
      settoastType(error.msg.error_type);
      return { signPsbt: "" };
    }
  };
  const addSellerPsbt = async (signPsbt: string) => {
    const toastId = toast.loading("please waiting...");
    try {
      await changeOrders({
        action: "offer",
        signed_psbt: signPsbt,
        orders: [
          {
            ticker: form.token,
            unit_price: Number(form.uintPrice),
            amount: Number(coinsInfo?.data.mint_amount) * Number(form.num),
            buyer_input_address: accounts[0],
            buyer_output_address: form.receiveAddress || accounts[0],
          },
        ],
      });
      toast.success("Success", {
        id: toastId,
      });
      localStorage.setItem(
        `listingAddress_${accounts[0]}`,
        form.receiveAddress,
      );
      closeModal(true);
    } catch (error) {
      toast.error("Error", {
        id: toastId,
      });
    }
  };
  const handleConfirm = async () => {
    if (!form.num) {
      toast.error("Please enter quantity", {
        id: "market",
      });
      return;
    }
    if (!form.uintPrice) {
      toast.error("Please enter price", {
        id: "market",
      });
      return;
    }
    const { signPsbt } = await getSellerPsbt();
    if (signPsbt) {
      await addSellerPsbt(signPsbt);
    }
  };
  useEffect(() => {
    return () => {
      if (!show) return;
      setTimeout(() => {
        setForm((draft) => {
          draft.num = "";
          draft.receiveAddress = "";
          draft.uintPrice = "";
          draft.loading = false;
        });
      }, 0);
    };
  }, [show]);
  useEffect(() => {
    if (show) {
      setForm((draft) => {
        draft.receiveAddress =
          localStorage.getItem(`listingAddress_${accounts[0]}`) || accounts[0];
      });
    }
  }, [accounts, show]);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-[#252524] py-6 text-left align-middle shadow-xl transition-all border border-[rgba(240,236,230,0.2)]">
                <Dialog.Title
                  as="h3"
                  className="text-xl px-6 font-medium text-[#F0ECE6] chill leading-none flex justify-between items-center mb-5"
                >
                  Make an Offer
                  <Image
                    className="cursor-pointer"
                    height={17}
                    src={closeIcon}
                    onClick={() => closeModal(false)}
                    alt=""
                  />
                </Dialog.Title>
                {!token ? (
                  <div className="flex items-center px-6 mb-5">
                    <span className="text-[#F0ECE6] inline-block w-[120px]">
                      Token to list
                    </span>
                    <Select
                      disabled={Boolean(token)}
                      value={form.token}
                      className="w-full"
                      placeholder={`${form.token ? form.token : "Select token"}`}
                      options={
                        tokenList?.data.list.map((item) => ({
                          label: item.name,
                          value: item.name,
                        })) || []
                      }
                      onChange={(e) => {
                        setForm((draft) => {
                          draft.token = e.toString();
                        });
                      }}
                    />
                  </div>
                ) : null}
                <div className="px-6">
                  <div className="text-[#F0ECE6] flex justify-between items-center mb-2">
                    Bulk offer
                    <span className="text-xs text-[#9E9C98] flex items-center leading-none select-none">
                      Quantity{" "}
                      {Number(form.num) * Number(coinsInfo?.data.mint_amount)}
                    </span>
                  </div>
                  <div className="flex flex-1 gap-2">
                    <div className="bg-[rgba(240,236,230,0.05)] rounded-md flex-1 flex items-center px-3">
                      <Range
                        value={Number(form.num)}
                        max={max}
                        onChange={(v) => {
                          setForm((draft) => {
                            draft.num = v + "";
                          });
                        }}
                      />
                    </div>
                    <input
                      className="w-[56px] h-[36px] outline-none border-none pl-3 rounded-md text-sm bg-[rgba(207,225,255,0.1)]"
                      type="number"
                      value={form.num}
                      placeholder="0"
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          setForm((draft) => {
                            draft.num = "";
                          });
                        } else if (Number(e.target.value) > max) {
                          setForm((draft) => {
                            draft.num = max.toString();
                          });
                        } else {
                          setForm((draft) => {
                            draft.num = e.target.value;
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="px-6 pt-5">
                  <Speed
                    value={form.fee.value}
                    index={form.fee.index}
                    onChange={(v, i) => {
                      setForm((draft) => {
                        draft.fee.value = v;
                        draft.fee.index = i;
                      });
                    }}
                  />
                  <div className="text-[#F0ECE6] text-sm font-medium mt-5">
                    Unit price
                    <br />
                    {averagePrice ? (
                      <span className="text-xs text-[#9E9C98] select-none">
                        The latest transaction price is{" "}
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            if (averagePrice) {
                              setForm((draft) => {
                                draft.uintPrice = `${averagePrice}`;
                              });
                            }
                          }}
                        >
                          {averagePrice} sats/$
                          {form.token}.
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="w-full flex items-center px-3 h-[36px] bg-[rgba(240,236,230,0.1)] rounded-md border-[1px solid rgba(240,236,230,0.2)] mt-2">
                    <input
                      type="number"
                      value={form.uintPrice}
                      placeholder={`satoshi per ${token}`}
                      className="flex-1 outline-none border-none text-[#F0ECE6]"
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          setForm((draft) => {
                            draft.uintPrice = "";
                          });
                        } else {
                          setForm((draft) => {
                            draft.uintPrice = e.target.value;
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="text-[#F0ECE6] text-sm font-medium mt-5">
                    Inscription receive address
                  </div>
                  <Select
                    autocomplete
                    value={form.receiveAddress}
                    onChange={(e) => {
                      setForm((draft) => {
                        draft.receiveAddress = e.toString();
                      });
                    }}
                    placeholder="Bitcoin address(optional)"
                    options={addressOptions.map((item) => ({
                      tag:
                        item.V1 === accounts[0]
                          ? "The current wallet "
                          : `Used ${item.V2} times `,
                      label: formatAddress(item.V1, 12, -10) || "",
                      value: item.V1,
                    }))}
                  />
                  {form.receiveAddress
                    ? !validateAddress(form.receiveAddress) && (
                        <div className="text-xs text-[#FF5A00] mt-1">
                          Invalid address
                        </div>
                      )
                    : null}
                  <div className="flex justify-between items-center mt-5">
                    <div>
                      <div className="text-[#F0ECE6] text-sm font-medium leading-none">
                        Total offer value
                      </div>
                      <div className="text-xs mt-2 text-[#9E9C98] leading-none">
                        Maker fee 0% limited time
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="flex items-center gap-1 text-[#FFAF5D] text-lg font-medium leading-none">
                        <Image width={11} src={buckIcon} alt="" />
                        {form.uintPrice && totalUtxos
                          ? satoshisToAmount(
                              totalUtxos * Number(form.uintPrice),
                              true,
                            )
                          : "--"}
                      </div>
                      <div className="text-xs mt-1 leading-none">
                        ${" "}
                        {form.uintPrice
                          ? formatNumber(
                              Number(
                                satoshisToAmount(
                                  Number(form.uintPrice) * totalUtxos,
                                  true,
                                ),
                              ) * usdPrice,
                            )
                          : "--"}
                      </div>
                    </div>
                  </div>
                  {accounts.length && connectedWallet ? (
                    <Button
                      loading={isPending || makePedding || form.loading}
                      className="w-full h-[44px] mt-5"
                      onClick={() => handleConfirm()}
                    >
                      Make an Offer
                    </Button>
                  ) : (
                    <Button
                      loading={form.loading}
                      className="w-full h-[44px] mt-5"
                      onClick={async () => {
                        if (!accounts.length || !connectedWallet) {
                          await walletContext?.connect();
                          closeModal(false);
                          return;
                        }
                      }}
                    >
                      Connect
                    </Button>
                  )}
                </div>
                <div className="text-center text-xs text-[#9E9C98] mt-5">
                  Canceling an offer will also pay transaction gas
                </div>
                {toastType ? (
                  <Toast
                    show
                    closeModal={() => {
                      settoastType(0);
                    }}
                  >
                    {toastType === 1 ? (
                      <>
                        In order to protect the safety of user funds, a single
                        discount cannot exceed US{" "}
                        <span className="text-[#AAFF5D]">$2,000 </span> during
                        the internal testing period. If a risk occurs, full
                        compensation will be provided.
                      </>
                    ) : (
                      <span>
                        The total offer amount during the internal beta cannot
                        exceed US{" "}
                        <span className="text-[#AAFF5D]">$50,000 </span>, please
                        try again later. This is to protect the safety of user
                        funds. If a risk occurs, full compensation will be
                        provided.
                      </span>
                    )}
                  </Toast>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MakeOffer;
