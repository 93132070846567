import { useQuery } from "@tanstack/react-query";
import { RQ_USDPRICE } from "../key";
import axios from "axios";
const useUsdPrice = () => {
  const result = useQuery<number>({
    queryKey: [RQ_USDPRICE],
    queryFn: () =>
      axios
        .get("/market/v1/btc_price")
        .then((res) => res.data.price)
        .catch(() => {
          return 0;
        }),
  });
  return result;
};
export default useUsdPrice;
