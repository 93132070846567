import { useQuery } from "@tanstack/react-query";
import { RQ_ADDRESS_TICKERS } from "../key";

import axios from "axios";
interface Response {
  tickers: string[];
}
export interface IProps {
  address: string;
}
const useAddressTickers = ({ address }: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_ADDRESS_TICKERS, address],
    queryFn: () =>
      axios
        .get(`/market/v1/addresses/${address}/tickers`)
        .then((res) => res.data),
    enabled: !!address,
  });
  return result;
};
export default useAddressTickers;
