import { useContext, useEffect, useState } from "react";
import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import useGetAccounts from "./useGetAccounts";
import { MarketContext } from "@/app/Context";

const useBalance = () => {
  const { connectedWallet } = useContext(MarketContext);
  const { wallet } = useWalletInstance();
  const { accounts } = useGetAccounts();
  const [banlans, setBalance] = useState<
    | {
        confirmed: number;
        unconfirmed: number;
        total: number;
      }
    | undefined
  >(undefined);
  const getBalance = async () => {
    if (connectedWallet === "wizz") {
      const res = await wallet?.getAtomicalsBalance();
      if (!res) return;
      setBalance({
        confirmed: res.confirmedValue,
        unconfirmed: res.unconfirmedSendValue,
        total: res.confirmedValue + res.unconfirmedSendValue,
      });
    } else {
      const res = await wallet?.getBalance();
      setBalance(res);
    }
  };
  useEffect(() => {
    getBalance();
  }, [wallet, accounts]);
  return banlans;
};
export default useBalance;
