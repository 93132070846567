import axios from "axios";
import { useMutation } from "@tanstack/react-query";

interface Params {
  id: string;
  status: 1 | 2 | 10; //1 - listing, 2 - unlist, 10 - withdraw offer
  signature: string;
  address: string;
  gas_fee?: number;
  refund_address?: string;
}
const useOrderStatus = () => {
  const mutation = useMutation<Response, unknown, Params>({
    mutationFn: (params) =>
      axios
        .post(`/market/v1/orders/${params.id}/status`, {
          status: params.status,
          signature: params.signature,
          address: params.address,
          gas_fee: params.gas_fee,
          refund_address: params.refund_address,
        })
        .then((res) => res?.data),
  });
  return mutation;
};
export default useOrderStatus;
