import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { RQ_COINSINFO } from "../key";
export interface CoinResultItem {
  created_at: number;
  holders: number;
  market_cap: number;
  max_supply: number;
  name: string;
  price: number;
  total_supply: number;
  trading_volume: number;
  perpetual_mint: boolean;
  mint_amount: number;
}
interface Response {
  data: CoinResultItem;
  result: boolean;
}
export interface IProps {
  id: string;
  refetchInterval?: number;
}
const useCoinsInfo = ({ id, refetchInterval }: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_COINSINFO, id],
    queryFn: async () => {
      return axios.get(`/api/v1/coins/${id}`, {}).then((res) => res.data || {});
    },
    enabled: !!id,
    refetchInterval,
  });

  return result;
};
export default useCoinsInfo;
