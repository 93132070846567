import { CoinsStatus } from "@/api/query/getCoinsStatus";
import { Wallet } from "@/components/unisat/ConnectWallet";
import { Dispatch, SetStateAction, createContext } from "react";
export const MarketContext = createContext<{
  wallet: Wallet | null;
  connectedWallet: Wallet["types"] | null;
  coinsStatus: CoinsStatus | null;
  setWallet: Dispatch<SetStateAction<Wallet | null>>;
  setConnectedWallet: Dispatch<SetStateAction<Wallet["types"] | null>>;
  setCoinsStatus: Dispatch<SetStateAction<CoinsStatus | null>>;
}>({
  connectedWallet: null,
  wallet: null,
  coinsStatus: null,
  setWallet: () => {},
  setConnectedWallet: () => {},
  setCoinsStatus: () => {},
});
