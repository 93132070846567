import BigNumber from "bignumber.js";
import { address, networks } from "bitcoinjs-lib";
import validate, { Network } from "bitcoin-address-validation";
import { format } from "date-fns";

import { envNetwork } from "./config";

export enum AddressType {
  P2PKH,
  P2WPKH,
  P2TR,
  P2SH_P2WPKH,
  M44_P2WPKH, // deprecated
  M44_P2TR, // deprecated
  P2WSH,
  P2SH,
}
export enum NetworkType {
  MAINNET,
  TESTNET,
  REGTEST,
}
export const satoshisToAmount = (val: number, zero = false, len = 8) => {
  if (!val) {
    return 0;
  }
  const num = new BigNumber(val);
  const result = num.dividedBy(100000000).toFixed(len);
  if (zero) {
    return result.replace(/\.?0*$/, "");
  }
  return result;
};

export const amountToSaothis = (val: any) => {
  const num = new BigNumber(val);
  return num.multipliedBy(100000000).toNumber();
};

export const satoshisToUsd = (val: number, usd: number) => {
  return Number(satoshisToAmount(val)) * usd;
};

export const formatNumber = (num: number, len = 5) => {
  if (!num) {
    return 0;
  }
  if (num < 1000) {
    return num.toFixed(len).replace(/\.?0*$/, "");
  } else if (num < 1000000) {
    return (num / 1000).toFixed(3) + " K";
  } else if (num < 1000000000) {
    return (num / 1000000).toFixed(3) + " M";
  } else {
    return (num / 1000000000).toFixed(3) + " B";
  }
};

export const formatPrice = (p: number | string) => {
  const price = Number(p);
  if (price >= 1) {
    return String(price).replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
      return s1.replace(/\d{1,3}(?=(\d{3})+$)/g, "$&,") + s2;
    });
  }
  return p;
};

export const formatAddress = (_address: string, start = 6, end = -4) => {
  if (!_address) return;
  return _address.slice(0, start) + "..." + _address.slice(end);
};

export const validateAddress = (_address: string) => {
  return validate(
    _address,
    envNetwork === "testnet" ? Network.testnet : Network.mainnet,
  );
};

export const timeAgo = (timestamp: number) => {
  if (!timestamp) return;
  const now = new Date().getTime();
  const diff =
    now - (timestamp.toString().length < 13 ? timestamp * 1000 : timestamp);

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (days > 0) {
    return `${days} Days ago`;
  }

  const hours = Math.floor(diff / (1000 * 60 * 60));
  if (hours > 0) {
    return `${hours} hrs ago`;
  }

  const minutes = Math.ceil(diff / (1000 * 60));
  return `${minutes} mins ago`;
};

/**
 * Timestamp conversion
 * @param {*} _date
 * @param {*} _format yyyy-MM-dd HH:mm:ss
 */
export const dateFormat = (_date: number, _format = "yyyy-MM-dd"): string =>
  _date
    ? format(_date.toString().length < 13 ? _date * 1000 : _date, _format)
    : "";

export const countDecimalPlaces = (number: number) => {
  const numberStr = number.toString();
  const decimalIndex = numberStr.indexOf(".");
  if (decimalIndex === -1) {
    return 0;
  } else {
    return numberStr.length - decimalIndex - 1;
  }
};

export function getAddressType(_address: string): AddressType | null {
  const network =
    envNetwork === "testnet" ? networks.testnet : networks.bitcoin;
  let type: AddressType | null = null;
  try {
    const decoded = address.fromBase58Check(_address);

    if (decoded.version === network.pubKeyHash) {
      type = AddressType.P2PKH;
    } else if (decoded.version === network.scriptHash) {
      type = AddressType.P2SH_P2WPKH; //P2SH
    }
  } catch (error) {
    try {
      // not a Base58 address, try Bech32
      const decodedBech32 = address.fromBech32(_address);

      if (decodedBech32.version === 0 && decodedBech32.data.length === 20) {
        type = AddressType.P2WPKH;
      } else if (
        decodedBech32.version === 0 &&
        decodedBech32.data.length === 32
      ) {
        type = AddressType.P2WSH;
      } else if (
        decodedBech32.version === 1 &&
        decodedBech32.data.length === 32
      ) {
        type = AddressType.P2TR;
      }
    } catch (err) {
      console.error("unsupport address type: " + address);
    }
  }
  return type;
}
export const calculateUtxoFundsRequired = (
  _address: string,
  numberOfInputs: number,
  numberOfOutputs: number,
  satsByte: number,
) => {
  const addressType = getAddressType(_address);
  let baseInputSize = 0;
  let baseOutputSize = 0;
  switch (addressType) {
    case AddressType.P2PKH:
      baseInputSize = 148;
      baseOutputSize = 34;
      break;
    case AddressType.P2WPKH:
      baseInputSize = 68;
      baseOutputSize = 31;
      break;
    case AddressType.P2SH_P2WPKH:
      baseInputSize = 68;
      baseOutputSize = 31;
      break;
    default:
      baseInputSize = 57.5;
      baseOutputSize = 43;
      break;
  }
  let expectedSatoshisDeposit =
    (numberOfInputs * baseInputSize + numberOfOutputs * baseOutputSize + 10.5) *
    satsByte;
  if (expectedSatoshisDeposit > 0 && expectedSatoshisDeposit < 546) {
    expectedSatoshisDeposit = 546;
  }
  return expectedSatoshisDeposit;
};
