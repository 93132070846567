import { ReactNode } from "react";
import { StaticImageData } from "next/image";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface IProps {
  id: string;
  mode: "hash" | "history";
  paths: {
    name: string;
    href: string;
    disabled?: boolean;
    icon?: StaticImageData;
    activeIcon?: StaticImageData;
  }[];
  after?: ReactNode;
  replace?: boolean;
}
const Menus = ({ id, mode, paths, replace, after }: IProps) => {
  const path = usePathname();
  const jumpAddress = (p: string) => {
    if (mode === "history") {
      return path.substring(0, path.lastIndexOf("/")) + "/" + p;
    } else {
      return path + "?tab=" + p;
    }
  };
  return (
    <div className="flex justify-between items-center">
      <div className="w-full h-12 text-sm text-[#7F7D7A] leading-none gap-4 flex font-semibold items-center">
        {paths.map((item, index) => (
          <div key={item.href} className="h-full flex items-center">
            <Link
              href={item.disabled ? "" : jumpAddress(item.href)}
              replace={replace}
              className={`${
                id === item.href ? "text-white border-b-2 border-[#AAFF5D]" : ""
              } h-full px-2 flex items-center ${item.disabled ? "disabled" : ""}`}
            >
              {item.icon && item.activeIcon ? (
                <Image
                  className="mr-2"
                  width={15}
                  src={id === item.href ? item.activeIcon : item.icon}
                  alt=""
                />
              ) : null}
              {item.disabled ? (
                <div className="tooltip tooltip-bottom" data-tip="Coming soon">
                  {item.name}
                </div>
              ) : (
                item.name
              )}
            </Link>
            {item.icon &&
              index !== paths.length - 1 &&
              (index + 1) % 2 === 0 && (
                <div className="w-[1px] h-1/3 bg-[#3C3B3A] ml-4" />
              )}
          </div>
        ))}
      </div>
      {after}
    </div>
  );
};

export default Menus;
