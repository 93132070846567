"use client";

import { MarketContext } from "@/app/Context";
import { Wallet } from "@/components/unisat/ConnectWallet";
import { useContext, useEffect, useState } from "react";
export type NetWork = "livenet" | "testnet";
type EventType = "accountsChanged" | "networkChanged";
type EventHandler = (...args: any[]) => void;

export interface UnisatType {
  on: (event: EventType, handler: EventHandler) => void;
  removeListener: (event: EventType, handler: EventHandler) => void;
  requestAccounts: () => Promise<string[]>;
  getAccounts: () => Promise<string[]>;
  getNetwork: () => Promise<NetWork>;
  switchNetwork: (network: NetWork) => Promise<string>;
  getPublicKey: () => Promise<string>;
  getBalance: () => Promise<{
    confirmed: number;
    unconfirmed: number;
    total: number;
  }>;
  getAtomicalsBalance: () => Promise<{
    confirmedValue: number;
    unconfirmedSendValue: number;
  }>;
  /**
   *
   * @param toAddress
   * @param satoshis
   * @param param2
   * @returns
   */
  sendBitcoin: (
    toAddress: string,
    satoshis: number,
    { feeRate }?: { feeRate?: number },
  ) => Promise<string>;
  /**
   *
   * @param address
   * @param inscriptionId
   * @param param2
   * @returns
   */
  sendInscription: (
    address: string,
    inscriptionId: string,
    { feeRate }?: { feeRate?: number },
  ) => Promise<{
    txid: string;
  }>;
  /**
   *
   * @param param0
   * @returns txid
   */
  pushTx: (rawtx: string) => Promise<string>;
  signPsbt: (
    psbtHex: string,
    {
      autoFinalized,
      toSignInputs,
    }: {
      autoFinalized: boolean;
      toSignInputs: {
        index: number;
        address?: string;
        publicKey?: string;
        sighashTypes?: number[];
      }[];
    },
  ) => Promise<string>;
  signPsbts: (
    psbtHex: string[],
    {
      autoFinalized,
      toSignInputs,
    }: {
      autoFinalized: boolean;
      toSignInputs: {
        index: number;
        address?: string;
        publicKey?: string;
        sighashTypes?: number[];
      }[];
    },
  ) => Promise<string[]>;
  pushPsbt: (psbtHex: string) => Promise<string>;
  signMessage: (
    msg: string,
    type?: "ecdsa" | "bip322-simple",
  ) => Promise<string>;
}
const useWalletInstance = () => {
  const { connectedWallet } = useContext(MarketContext);
  const [wallet, setWallet] = useState<UnisatType | null>(null);
  const getInstance = (types: Wallet["types"]): UnisatType | null => {
    if (types === "unisat") {
      return window.unisat;
    }
    if (types === "wizz") {
      return window.wizz;
    }
    if (types === "OKX") {
      return window.okxwallet.bitcoin;
    }
    return null;
  };
  useEffect(() => {
    if (connectedWallet === "unisat") {
      setWallet(window.unisat);
    } else if (connectedWallet === "wizz") {
      setWallet(window.wizz);
    } else if (connectedWallet === "OKX") {
      setWallet(window.okxwallet.bitcoin);
    }
  }, [connectedWallet]);
  return { wallet, getInstance };
};
export default useWalletInstance;
