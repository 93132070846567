import { useQuery } from "@tanstack/react-query";
import { RQ_ADDRESSBALANCES } from "../key";
import axios from "axios";
interface Response {
  data: Record<string, number>;
  result: boolean;
}
interface IProps {
  page?: number;
  page_size?: number;
  address: string;
}
const useAddressesBalances = ({ address }: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_ADDRESSBALANCES, address],
    queryFn: async () => {
      return axios.get(`/api/v1/addresses/${address}`).then((res) => res.data);
    },
    enabled: !!address,
  });
  return result;
};
export default useAddressesBalances;
