"use client";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
// import Cookies from "js-cookie";
import Link from "next/link";
import Image from "next/image";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Menu, Transition } from "@headlessui/react";
import { MarketContext } from "@/app/Context";
import useGetAccounts from "@/hooks/wallet/useGetAccounts";
import UnisatInstance from "@/hooks/wallet/useWalletInstance";
import { envNetwork } from "@/plugins/config";
import { buildMempoolUrl } from "@/api/utils";
import useBalance from "@/hooks/wallet/useBalance";
import { formatAddress, formatPrice, satoshisToAmount } from "@/plugins/utils";
import { SelectWallet } from "@/components";
import { useAddressesBalances, useAddressesTokens } from "@/api/query";
import {
  angleDownIcon,
  buckIcon,
  copyIcon,
  rightArrowIcon,
  shareIcon,
  unisatIcon,
  walletIcon,
  wizzIcon,
  okxIcon,
} from "@/assets";
import { useProxy } from "@/api/mutation";
export interface Wallet {
  requestAccounts: (types: Wallet["types"]) => Promise<string[] | undefined>;
  setConnect: (b: boolean, types?: Wallet["types"]) => void;
  closeMenu: () => void;
  connect: () => void;
  sign: (address: string) => void;
  types?: "wizz" | "unisat" | "OKX";
}
interface IProps {
  onClose?: () => void;
}
const ConnectWallet = ({ onClose }: IProps) => {
  const { getInstance } = UnisatInstance();
  const { setWallet, connectedWallet, setConnectedWallet } =
    useContext(MarketContext);
  const { accounts, setAccounts } = useGetAccounts();
  const balance = useBalance();
  const { mutateAsync } = useProxy();
  const [showMenu, setShowMenu] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const { data } = useAddressesBalances({ address: accounts[0] });
  const { data: dataTokens } = useAddressesTokens({
    address: accounts[0],
    page_size: 15,
  });
  const balances = useMemo(() => {
    const sum = Object.values(data?.data || 0).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
    return Number(balance?.total) - sum;
  }, [data, balance?.total]);
  const walletImg = useMemo(() => {
    const walletIcons = {
      unisat: unisatIcon,
      wizz: wizzIcon,
      OKX: okxIcon,
    };

    return accounts.length && connectedWallet
      ? walletIcons[connectedWallet] || walletIcon
      : walletIcon;
  }, [accounts.length, connectedWallet]);

  const setConnect = (b: boolean, types?: Wallet["types"]) => {
    if (b) {
      localStorage.setItem("connected_wallet", types || "");
      setConnectedWallet(types);
    } else {
      localStorage.removeItem("connected_wallet");
      localStorage.removeItem("address");
      setConnectedWallet(undefined);
    }
    if (!b) {
      setShowMenu(false);
    }
  };
  /**
   * get bitatom session timestamp
   * @returns
   */
  const getTimestamp = async () => {
    const res = await mutateAsync({
      cmd: "request.get",
      url: "/api/trpc/auth.getSession",
      postData: {
        batch: 1,
        input: {
          0: {
            json: null,
            meta: { values: ["undefined"] },
          },
        },
      },
    });
    return res[0]?.result?.data?.json.timestamp;
  };
  const getAccessToken = async ({
    timestamp,
    address,
    signature,
    pubkey,
    wallet,
  }: {
    timestamp: number;
    address: string;
    signature: string;
    pubkey: string;
    wallet: string;
  }) => {
    await mutateAsync({
      cmd: "request.get",
      url: "/api/trpc/auth.getAccessToken",
      postData: {
        batch: 1,
        input: {
          "0": {
            json: {
              timestamp,
              address,
              signature,
              pubkey,
              wallet: wallet.charAt(0).toUpperCase() + wallet.slice(1),
            },
          },
        },
      },
    });
  };
  const signTimestamp = async (address = accounts[0]) => {
    if (!connectedWallet) return;
    const timestamp = await getTimestamp();
    const wallet = getInstance(connectedWallet);
    const sign = await wallet?.signMessage(timestamp.toString());
    if (!sign) {
      return;
    }
    const pubkey = await wallet?.getPublicKey();
    await getAccessToken({
      timestamp,
      address,
      signature: sign,
      pubkey: pubkey || "",
      wallet: connectedWallet || "",
    });
  };
  const requestAccounts = async (types: Wallet["types"]) => {
    if (types === "unisat" && typeof window.unisat === "undefined") {
      window.open(
        "https://chromewebstore.google.com/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo",
      );
      return;
    }
    if (types === "wizz" && typeof window.wizz === "undefined") {
      window.open(
        "https://chromewebstore.google.com/detail/wizz-walletformerly-atom/ghlmndacnhlaekppcllcpcjjjomjkjpg",
      );
      return;
    }
    if (types === "OKX" && typeof window.okxwallet === "undefined") {
      window.open(
        "https://chromewebstore.google.com/detail/%E6%AC%A7%E6%98%93-web3-%E9%92%B1%E5%8C%85/mcohilncbfahbmgdjkbpemcciiolgcge",
      );
      return;
    }
    if (accounts.length && connectedWallet) {
      console.log("already connected");
      setShowMenu(!showMenu);
      return accounts;
    }
    const wallet = getInstance(types);
    try {
      const getAccounts = await wallet?.requestAccounts();
      const walletNetwork = await wallet?.getNetwork();
      if (envNetwork !== walletNetwork) {
        try {
          await wallet?.switchNetwork(envNetwork);
        } catch (error) {
          console.log(error);
          return;
        }
      }
      if (getAccounts?.length) {
        setAccounts(getAccounts);
      }
      setConnect(true, types);
      setShowMenu(false);
      return getAccounts;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const handleCopyClick = () => {
    toast.success("copy success");
  };
  useEffect(() => {
    setWallet({
      requestAccounts,
      setConnect,
      closeMenu() {
        setShowMenu(false);
      },
      connect() {
        setShowSelect(true);
      },
      sign: async (address: string) => {
        signTimestamp(address);
      },
    });
  }, [accounts, connectedWallet, setShowMenu, showMenu]);
  return (
    <div className="h-10 md:h-8 min-h-8 text-base bg-[#AAFF5D] text-black md:text-xs px-3 rounded-md">
      <Menu as="div" className="relative inline-block text-left w-full h-full">
        <div className="h-full">
          <Menu.Button
            className="w-full h-full flex items-center justify-between chill gap-2"
            onClick={(e) => {
              e.stopPropagation();
              if (accounts.length && connectedWallet) {
                setShowMenu(!showMenu);
              } else {
                setShowSelect(true);
              }
            }}
          >
            <span className="flex items-center gap-1">
              <Image src={walletImg} width={14} alt="" />
              {accounts.length && connectedWallet
                ? formatAddress(accounts[0])
                : "Connect wallet"}
            </span>
            {accounts.length && connectedWallet ? (
              <Image src={angleDownIcon} width={12} alt="" />
            ) : null}
          </Menu.Button>
        </div>
        <Transition
          show={showMenu}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-full md:w-[200px] origin-top-right bg-[#252524] border border-[rgba(240,236,230,0.2)] rounded-lg focus:outline-none z-10">
            <div className="py-2 border-b border-[rgba(240,236,230,0.05)]">
              <CopyToClipboard
                text={accounts[0]}
                onCopy={() => {
                  handleCopyClick();
                }}
              >
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? "bg-[rgba(255,255,255,0.05)]" : ""
                      } flex w-full items-center h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                    >
                      {accounts[0]?.slice(0, 6) +
                        "..." +
                        accounts[0]?.slice(-4)}

                      <Image
                        width={12}
                        className="ml-2"
                        src={copyIcon}
                        alt=""
                      />
                    </div>
                  )}
                </Menu.Item>
              </CopyToClipboard>
              <Menu.Item>
                {({ active }) => (
                  <a
                    target="_blank"
                    href={buildMempoolUrl(`/address/${accounts[0]}`)}
                    className={`${
                      active ? "bg-[rgba(255,255,255,0.05)]" : ""
                    } flex w-full items-center h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                    rel="noreferrer"
                  >
                    mempool
                    <Image width={12} className="ml-2" src={shareIcon} alt="" />
                  </a>
                )}
              </Menu.Item>
            </div>
            <div className="py-2 border-b border-[rgba(240,236,230,0.05)]">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${
                      active ? "bg-[rgba(255,255,255,0.05)]" : ""
                    } flex w-full items-center justify-between h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                  >
                    BTC
                    <div className="flex gap-2 items-center">
                      <Image
                        width={12}
                        className="ml-2"
                        src={buckIcon}
                        alt=""
                      />
                      <span>
                        {formatPrice(satoshisToAmount(balances, true, 5))}
                      </span>
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${
                      active ? "bg-[rgba(255,255,255,0.05)]" : ""
                    } flex w-full items-center justify-between h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                  >
                    Inscription
                    <div className="flex gap-2 items-center">
                      <Image
                        width={12}
                        className="ml-2"
                        src={buckIcon}
                        alt=""
                      />
                      <span>
                        {formatPrice(
                          satoshisToAmount(
                            dataTokens?.data.total_price || 0,
                            true,
                            5,
                          ),
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
            <div className="py-2">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={`/address/${accounts[0]}`}
                    onClick={() => {
                      onClose?.();
                    }}
                    className={`${
                      active ? "bg-[rgba(255,255,255,0.05)]" : ""
                    } flex w-full items-center justify-between h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                  >
                    My portfolio
                    <Image
                      width={8}
                      className="ml-2"
                      src={rightArrowIcon}
                      alt=""
                    />
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${
                      active ? "bg-[rgba(255,255,255,0.05)]" : ""
                    } flex w-full items-center justify-between h-9 text-[#F0ECE6] cursor-pointer px-4 text-sm font-medium`}
                    onClick={() => setConnect(false)}
                  >
                    Disconnect
                    <Image
                      width={8}
                      className="ml-2"
                      src={rightArrowIcon}
                      alt=""
                    />
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <SelectWallet
        show={showSelect}
        closeModal={() => {
          setShowSelect(false);
        }}
      />
    </div>
  );
};
export default ConnectWallet;
