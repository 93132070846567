import { useQuery } from "@tanstack/react-query";
import { RQ_COINSSTATUS } from "../key";
import axios from "axios";
export interface CoinsStatus {
  indexed_height: number;
  network: string;
  total_bitcoin_carved: number;
  total_carving_coins: number;
  total_holders: number;
  total_txs: number;
  coin_masks: Record<string, number>;
}
interface Response {
  data: CoinsStatus;
  result: boolean;
}
const useCoinsStatus = () => {
  const result = useQuery<Response>({
    queryKey: [RQ_COINSSTATUS],
    queryFn: async () => {
      return axios.get("/api/v1/status").then((res) => res.data);
    },
  });
  return result;
};
export default useCoinsStatus;
