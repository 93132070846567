import axios from "axios";
import { useMutation } from "@tanstack/react-query";
type Params = {
  action:
    | "buy"
    | "listing"
    | "notify"
    | "offer"
    | "accept"
    | "transform"
    | "burn";
  signed_psbt: string;
  tx_hash?: string;
  orders?: [
    {
      ticker: string;
      unit_price: number;
      amount: number;
      buyer_input_address: string;
      buyer_output_address: string;
    },
  ];
};

const useOrders = () => {
  const mutation = useMutation<Response, unknown, Params>({
    mutationFn: (params) =>
      axios.post("/market/v1/orders", params).then((res) => res?.data),
  });
  return mutation;
};
export default useOrders;
