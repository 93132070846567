import { useQuery } from "@tanstack/react-query";
import { RQ_COINSLIST } from "../key";
import axios from "axios";
import { useEffect, useState } from "react";
export interface CoinResultItem {
  created_at: number;
  holders: number;
  market_cap: number;
  max_supply: number;
  name: string;
  price: number;
  total_supply: number;
  trading_volume: number;
  price_change_1d: number;
  price_change_7d: number;
  perpetual_mint: boolean;
}
interface Response {
  data: {
    list: CoinResultItem[];
    total: number;
    total_volume_24h: number;
  };
  result: boolean;
}
export interface IProps {
  page?: number;
  page_size?: number;
  sorted_by?:
    | "trading_volume"
    | "market_cap"
    | "price"
    | "holder_count"
    | "price_change_7d"
    | "price_change_1d";
  window_period?: "24h" | "7d" | "30d";
  dir?: "desc" | "asc";
  localSort?: boolean;
  refetchInterval?: number;
}
const useCoinsList = ({
  page = 1,
  page_size = 10,
  sorted_by = "trading_volume",
  window_period = "24h",
  dir = "desc",
  localSort,
  refetchInterval = 5 * 1000,
}: IProps = {}) => {
  const [hotData, setHotData] = useState<Response>();
  const soryList = () => {
    switch (sorted_by) {
      case "trading_volume":
        return hotData?.data.list.sort(
          (a, b) => a.trading_volume - b.trading_volume,
        );
      case "price":
        return hotData?.data.list.sort((a, b) => a.price - b.price);
      case "holder_count":
        return hotData?.data.list.sort((a, b) => a.holders - b.holders);
      case "market_cap":
        return hotData?.data.list.sort((a, b) => a.market_cap - b.market_cap);
      case "price_change_1d":
        return hotData?.data.list.sort(
          (a, b) => a.price_change_1d - b.price_change_1d,
        );
      case "price_change_7d":
        return hotData?.data.list.sort(
          (a, b) => a.price_change_7d - b.price_change_7d,
        );
      default:
        return [];
    }
  };
  const result = useQuery<Response>({
    queryKey: [RQ_COINSLIST, page, page_size, sorted_by, window_period, dir],
    queryFn: async () => {
      if (dir === "asc" && localSort) {
        const list = soryList() || [];
        return new Promise((resove) =>
          resove({
            data: {
              list,
              total: hotData?.data.total || 0,
              total_volume_24h: hotData?.data.total_volume_24h || 0,
            },
            result: true,
          }),
        );
      }
      return axios
        .get("/api/v1/coins", {
          params: {
            page,
            page_size,
            sorted_by,
            window_period,
            dir,
          },
        })
        .then((res) => res.data);
    },
    refetchInterval,
  });
  const { data } = useQuery<Response>({
    queryKey: [RQ_COINSLIST, page, page_size, sorted_by, window_period, dir],
    enabled: !!localSort,
  });
  useEffect(() => {
    setHotData(data);
  }, [data]);

  return result;
};
export default useCoinsList;
