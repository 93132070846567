import { Tab } from "@headlessui/react";
interface IProps {
  items: { label: string; value: string | number; disabled?: boolean }[];
  defaultIndex?: number;
  onChange?: (value: number) => void;
}
const Tabs = ({ items, defaultIndex, onChange }: IProps) => {
  return (
    <Tab.Group selectedIndex={defaultIndex} onChange={onChange}>
      <Tab.List className="flex bg-[rgba(240,236,230,0.1)] rounded-md border border-[rgba(240,236,230,0.2)] p-[1px]">
        {items.map((item) => (
          <Tab
            disabled={item.disabled}
            key={item.label}
            className={({ selected }) => {
              return `text-xs font-semibold text-[#F0ECE6] outline-none py-[6px] px-2 ${
                selected ? "bg-[#F0ECE6] text-black rounded-md" : ""
              } ${item.disabled ? "disabled" : ""}`;
            }}
          >
            {item.disabled ? (
              <div className="tooltip tooltip-bottom" data-tip="Coming soon">
                {item.label}
              </div>
            ) : (
              item.label
            )}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default Tabs;
