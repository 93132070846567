import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { closeIcon, unisatIcon, wizzIcon } from "@/assets";
import { MarketContext } from "@/app/Context";

interface IProps {
  show: boolean;
  closeModal?: (status: boolean) => void;
}
const SelectWallet = ({ show, closeModal = () => {} }: IProps) => {
  const { wallet } = useContext(MarketContext);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full  max-w-md transform overflow-hidden rounded-xl bg-[#252524] py-6 text-left align-middle shadow-xl transition-all border border-[rgba(240,236,230,0.2)]">
                <Dialog.Title
                  as="h3"
                  className="text-xl px-6 font-medium text-[#F0ECE6] chill leading-none flex justify-between items-center mb-5"
                >
                  Select a wallet to connect
                  <Image
                    className="cursor-pointer"
                    height={17}
                    src={closeIcon}
                    onClick={() => closeModal(false)}
                    alt=""
                  />
                </Dialog.Title>
                <div
                  className="py-2 hover:bg-[rgba(255,255,255,0.05)] flex w-full items-center text-[#F0ECE6] cursor-pointer px-4 font-bold text-base chill gap-4"
                  onClick={async () => {
                    closeModal(false);
                    wallet?.requestAccounts("unisat");
                  }}
                >
                  <Image width={40} src={unisatIcon} alt="" />
                  Unisat
                </div>
                <div
                  className="py-2 hover:bg-[rgba(255,255,255,0.05)] flex w-full items-center text-[#F0ECE6] cursor-pointer px-4 font-bold text-base chill gap-4"
                  onClick={() => {
                    closeModal(false);
                    wallet?.requestAccounts("wizz");
                  }}
                >
                  <Image width={40} src={wizzIcon} alt="" />
                  Wizz
                </div>
                {/* <div
                  className="py-2 hover:bg-[rgba(255,255,255,0.05)] flex w-full items-center text-[#F0ECE6] cursor-pointer px-4 font-bold text-base chill gap-4"
                  onClick={() => {
                    closeModal(false);
                    wallet?.requestAccounts("OKX");
                  }}
                >
                  <Image width={40} src={okxIcon} alt="" />
                  OKX
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SelectWallet;
