import { useQuery } from "@tanstack/react-query";
import { RQ_LISTABLE } from "../key";
import axios from "axios";
export interface UtxoItem {
  Amount: number;
  CoinId: string;
  Owner: string;
  Utxo: string;
}
interface Response {
  utxos: UtxoItem[];
  total: number;
}
export interface IProps {
  address: string;
  ticker: string;
  limit?: number;
  refetchInterval?: number;
  enabled: boolean;
}
const useListable = ({
  address,
  ticker,
  enabled,
  refetchInterval,
  limit = 9999,
}: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_LISTABLE, address, ticker],
    queryFn: () =>
      axios
        .get(`/market/v1/addresses/${address}/listable`, {
          params: {
            ticker,
            limit: limit,
          },
        })
        .then((res) => res.data),
    enabled,
    refetchInterval,
  });
  return result;
};
export default useListable;
