import { useQuery } from "@tanstack/react-query";
import { RQ_TRADINGS } from "../key";
import axios from "axios";
export interface TradingsResultItem {
  Amount: number;
  Buyer: string;
  CoinId: string;
  Id: number;
  Price: number;
  Seller: string;
  Ts: string;
  Txid: string;
}
interface Response {
  data: {
    list: TradingsResultItem[];
    total: number;
  };
  result: boolean;
}
interface IProps {
  page?: number;
  page_size?: number;
  id: string;
  refetchInterval?: number;
}
const useTradings = ({
  page = 1,
  page_size = 30,
  id,
  refetchInterval,
}: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_TRADINGS, page, page_size, id],
    queryFn: async () => {
      return axios
        .get(`/api/v1/tradings/${id}`, {
          params: {
            page,
            page_size,
          },
        })
        .then((res) => res.data);
    },
    enabled: !!id,
    refetchInterval,
  });
  return result;
};
export default useTradings;
