import { memo } from "react";

interface IProps {
  type?: 1 | 2 | 3;
  className?: string;
  text?: string;
}
const Loading = ({ type = 1, className = "loading-xs" }: IProps) => {
  if (type === 1) {
    return <span className={`loading loading-spinner ${className}`} />;
  }
  if (type === 3) {
    return (
      <div className="flex items-center">
        <div className="loading4">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="loading3 ml-2">Buying</div>
      </div>
    );
  }
  return (
    <div className="loading2">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
export default memo(Loading);
