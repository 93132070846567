import Image from "next/image";
import { searchIcon } from "@/assets";
const Search = () => {
  return (
    <div className="tooltip tooltip-bottom" data-tip="Coming soon">
      <div className="w-[280px] h-8 flex items-center rounded-lg border border-[rgba(240,236,230,0.2)] bg-[rgba(240,236,230,0.1)] px-3 gap-1">
        <Image src={searchIcon} width={12} alt="" />
        <input
          type="text"
          disabled
          placeholder="token/address..."
          className="bg-transparent text-sm text-white border-none outline-none flex-1 disabled"
        />
      </div>
    </div>
  );
};
export default Search;
