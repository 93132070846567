import { useEffect, useRef, useState } from "react";
import { useChainFeeRate } from "@/api/query";
import { Loading } from "@/components";

interface IProps {
  value: number;
  index: number;
  onChange: (v: number, i: number) => void;
}
const Speed = ({ value, index, onChange }: IProps) => {
  const { data, isLoading, isSuccess } = useChainFeeRate({
    refetchInterval: 10 * 1000,
  });
  const [satIndex, setSatIndex] = useState(index);
  const [sat, setSat] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isSuccess && !value) {
      onChange(data.fastestFee, satIndex);
    }
  }, [isSuccess, satIndex]);
  useEffect(() => {
    setSatIndex(index);
  }, [index]);
  useEffect(() => {
    if (satIndex === 3) {
      inputRef.current?.focus();
    }
  }, [satIndex]);
  useEffect(() => {
    if (satIndex === 3) {
      onChange(Number(sat), satIndex);
    }
  }, [sat, satIndex]);
  useEffect(() => {
    if (data && satIndex !== undefined) {
      if (satIndex === 0) {
        onChange(data.hourFee, satIndex);
      } else if (satIndex === 1) {
        onChange(data.halfHourFee, satIndex);
      } else if (satIndex === 2) {
        onChange(data.fastestFee, satIndex);
      }
    }
  }, [data, satIndex]);
  return (
    <>
      <div className="flex justify-center items-center gap-2">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div
              className={`flex-1 h-[72px] bg-[rgba(240,236,230,0.1)] rounded-md border hover:border-[#AAFF5D] ${
                satIndex === 0 ? "border-[#AAFF5D]" : "border-transparent"
              }`}
              onClick={() => {
                if (!data?.hourFee) return;
                onChange(data.hourFee, 0);
              }}
            >
              <div className="flex flex-col justify-center items-center h-full cursor-pointer">
                <p className="text-sm text-[#AAFF5D] leading-none">~ 60 mins</p>
                <p className="text-xs leading-none mt-1 text-[#F0ECE6] font-bold">
                  <span className="text-base">{data?.hourFee}</span> sats/vB
                </p>
              </div>
            </div>
            <div
              className={`flex-1 h-[72px] bg-[rgba(240,236,230,0.1)] rounded-md border  hover:border-[#AAFF5D] ${
                satIndex === 1 ? "border-[#AAFF5D]" : "border-transparent"
              }`}
              onClick={() => {
                if (!data?.halfHourFee) return;
                onChange(data.halfHourFee, 1);
              }}
            >
              <div className="flex flex-col justify-center items-center h-full cursor-pointer">
                <p className="text-sm text-[#AAFF5D] leading-none">~ 30 mins</p>
                <p className="text-xs leading-none mt-1 text-[#F0ECE6] font-bold">
                  <span className="text-base">{data?.halfHourFee}</span> sats/vB
                </p>
              </div>
            </div>
            <div
              className={`flex-1 h-[72px] bg-[rgba(240,236,230,0.1)] rounded-md border  hover:border-[#AAFF5D] ${
                satIndex === 2 ? "border-[#AAFF5D]" : "border-transparent"
              }`}
              onClick={() => {
                if (!data?.fastestFee) return;
                onChange(data.fastestFee, 2);
              }}
            >
              <div className="flex flex-col justify-center items-center h-full cursor-pointer">
                <p className="text-sm text-[#AAFF5D] leading-none">~ 10 mins</p>
                <p className="text-xs leading-none mt-1 text-[#F0ECE6] font-bold">
                  <span className="text-base">{data?.fastestFee}</span> sats/vB
                </p>
              </div>
            </div>
            <div
              className={`flex-1 h-[72px] bg-[rgba(240,236,230,0.1)] rounded-md border  hover:border-[#AAFF5D] ${
                satIndex === 3 ? "border-[#AAFF5D]" : "border-transparent"
              }`}
              onClick={() => {
                if (!data?.halfHourFee) return;
                onChange(data.halfHourFee, 3);
              }}
            >
              <div className="flex flex-col justify-center items-center h-full cursor-pointer">
                <p className="text-sm text-[#AAFF5D] leading-none">Custom</p>
                <p className="text-xs leading-none mt-1 text-[#F0ECE6] font-bold">
                  <span className="text-base">{sat || "-"}</span> sats/vB
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {satIndex === 3 ? (
        <div className="w-full flex items-center px-3 h-[36px] bg-[rgba(240,236,230,0.1)] rounded-md border-[1px solid rgba(240,236,230,0.2)] mt-2">
          <input
            ref={inputRef}
            type="number"
            value={sat}
            placeholder="0"
            className="flex-1 outline-none border-none text-[#F0ECE6]"
            onKeyDown={(e) => {
              if (e.key === "." || e.key === "e") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                setSat("");
              } else if (
                Number(e.target.value) >
                Number(data?.halfHourFee) * 10
              ) {
                setSat((Number(data?.halfHourFee) * 10).toString());
              } else {
                setSat(e.target.value.replace(/[^\d]/g, ""));
              }
            }}
          />
          <span className="text-sm text-[#858480]">sats/vB</span>
        </div>
      ) : null}
    </>
  );
};
export default Speed;
