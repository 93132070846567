import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { Psbt, Transaction, networks } from "bitcoinjs-lib";
import { Dialog, Transition } from "@headlessui/react";
import { Select, Button, Loading, Range } from "@/components";
import {
  useUsdPrice,
  useListable,
  useCoinsInfo,
  useAddressesBalances,
  // useTradings,
  useAddressFua,
} from "@/api/query";
import { useGetPsbt, useOrders } from "@/api/mutation";
import useGetAccounts from "@/hooks/wallet/useGetAccounts";
import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import { envNetwork } from "@/plugins/config";
import {
  countDecimalPlaces,
  // countDecimalPlaces,
  formatAddress,
  formatNumber,
  formatPrice,
  satoshisToAmount,
  satoshisToUsd,
  validateAddress,
} from "@/plugins/utils";
import { closeIcon, deleteIcon, buckIcon } from "@/assets";
import { MarketContext } from "@/app/Context";
import { OrderItem } from "@/api/query/getGetOrders";
interface IProps {
  show: boolean;
  token: string;
  order?: OrderItem[];
  closeModal?: (status: boolean) => void;
}
const PendingOrder = ({
  show,
  token = "",
  order,
  closeModal = () => {},
}: IProps) => {
  const [form, setForm] = useImmer({
    num: "",
    receiveAddress: "",
    uintPrice: "",
    isPack: false,
    loading: false,
    token,
  });
  const { wallet } = useWalletInstance();
  const { connectedWallet, wallet: walletContext } = useContext(MarketContext);
  const { accounts } = useGetAccounts();
  const { data: dataUtxos, isLoading } = useListable({
    address: accounts[0],
    ticker: form.token,
    enabled: !!accounts[0] && !!form.token && show,
  });
  const [max, setMax] = useState(0);
  const { data: usdPrice = 0 } = useUsdPrice();
  const { data: addressesBalances } = useAddressesBalances({
    address: accounts[0],
  });
  const { data: listingAddress, refetch: refetchListingAddress } =
    useAddressFua({
      type: "listing",
      address: accounts[0],
      enabled: !!accounts[0] && show,
    });
  const { data: coinsInfo } = useCoinsInfo({ id: form.token });
  const { mutateAsync: getPsbt, isPending } = useGetPsbt();
  const { mutateAsync: changeOrders, isPending: listingPedding } = useOrders();
  // const { data } = useTradings({
  //   page: 1,
  //   page_size: 10,
  //   id: token,
  // });
  const [removeIds, setRemoveIds] = useImmer<string[]>([]);
  const utoxsList = useMemo(() => {
    return dataUtxos?.utxos
      ?.filter((item) => !removeIds.includes(item.Utxo))
      .slice(0, Number(form.num));
  }, [dataUtxos, removeIds, form.num]);
  const totalUtxos = useMemo(() => {
    if (order?.length) {
      return order.reduce((pre, current) => pre + current.Amount, 0);
    }
    if (utoxsList?.length) {
      return utoxsList.reduce((pre, current) => pre + current.Amount, 0);
    }
    return 0;
  }, [utoxsList, order]);
  const averagePrice = useMemo(() => {
    const len = countDecimalPlaces(coinsInfo?.data?.price || 0);
    return `${coinsInfo?.data?.price.toFixed(len ? 2 : 0)}` || "0";
    // const priceTotal = data?.data.list.reduce(
    //   (pre, current) => pre + current.Price / current.Amount,
    //   0,
    // );
    // console.log(priceTotal, "data?.data.list");
    // if (priceTotal && data?.data.list && data?.data.list.length >= 10) {
    // const len = countDecimalPlaces(
    //   data.data.list[0].Price / data.data.list[0].Amount,
    // );
    //   return (priceTotal / 10).toFixed(len ? 2 : 0);
    // }
    // return 0;
  }, [coinsInfo]);
  const addressOptions = useMemo(() => {
    const addressList = listingAddress?.addresses || [];
    if (addressList.length) {
      return addressList;
    }
    if (accounts.length) {
      return [{ V1: accounts[0], V2: 1 }];
    }
    return [];
  }, [listingAddress]);
  const handleSignPsbt = async (hex: string) => {
    const psbt = Psbt.fromBase64(hex, {
      network: envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
    });
    const publicKey = await wallet?.getPublicKey();
    try {
      const signPsbt = await wallet?.signPsbt(hex, {
        autoFinalized: false,
        toSignInputs: psbt.data.inputs.map((item, index) => ({
          index,
          address: accounts[0],
          publicKey: publicKey,
          sighashTypes: [
            Transaction.SIGHASH_SINGLE | Transaction.SIGHASH_ANYONECANPAY,
          ],
        })),
      });
      if (signPsbt) {
        const basePsbt = Psbt.fromHex(signPsbt, {
          network:
            envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
        });
        return basePsbt.toBase64();
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const addSellerPsbt = async (signPsbt: string) => {
    const toastId = toast.loading("please waiting...");
    try {
      await changeOrders({
        action: "listing",
        signed_psbt: signPsbt,
      });
      toast.success("Success", {
        id: toastId,
      });
      localStorage.setItem(
        `listingAddress_${accounts[0]}`,
        form.receiveAddress,
      );
      closeModal(true);
    } catch (error) {
      toast.error("Error", {
        id: toastId,
      });
    }
  };
  const getSellerPsbt = async () => {
    const orders = order?.length
      ? order.map((item) => ({
          unit_price: Number(form.uintPrice),
          seller_input_address: accounts[0],
          seller_output_address: form.receiveAddress || accounts[0],
          utxo_hash: item.utxo_hash,
          utxo_index: item.utxo_index,
          utxo_value: item.utxo_value,
        }))
      : utoxsList?.map((item) => ({
          unit_price: Number(form.uintPrice),
          seller_input_address: accounts[0],
          seller_output_address: form.receiveAddress || accounts[0],
          utxo_hash: item.Utxo.split(":")[0],
          utxo_index: Number(item.Utxo.split(":")[1]),
          utxo_value: item.Amount,
        })) || [];
    const { psbt, sign_flags: signFlags } = await getPsbt({
      action: "listing",
      orders,
    });
    refetchListingAddress();
    const signPsbt = await handleSignPsbt(psbt);
    return { signPsbt, signFlags };
  };
  const handleConfirm = async () => {
    if (!form.uintPrice) {
      toast.error("Please enter price", {
        id: "market",
      });
      return;
    }
    const { signPsbt } = await getSellerPsbt();
    if (signPsbt) {
      await addSellerPsbt(signPsbt);
    }
  };
  useEffect(() => {
    return () => {
      if (!show) return;
      setTimeout(() => {
        setForm((draft) => {
          draft.num = "";
          draft.receiveAddress = "";
          draft.uintPrice = "";
          draft.isPack = true;
          draft.loading = false;
        });
        setRemoveIds([]);
      }, 0);
    };
  }, [show]);
  useEffect(() => {
    if (order?.length && order?.[0].unit_price && show) {
      setForm((draft) => {
        draft.uintPrice = order?.[0].unit_price;
      });
    }
  }, [order, show]);
  useEffect(() => {
    if (dataUtxos?.utxos && show) {
      setMax(dataUtxos.total || 0);
    }
  }, [dataUtxos?.utxos, show]);
  useEffect(() => {
    if (dataUtxos && show) {
      setForm((draft) => {
        draft.num = dataUtxos.total > 20 ? "20" : dataUtxos.total.toString();
      });
    }
  }, [dataUtxos, show]);
  useEffect(() => {
    if (addressesBalances && show && !token) {
      if (!addressesBalances.data[token]) {
        setForm((draft) => {
          draft.token = Object.keys(addressesBalances.data)[0];
        });
      }
    }
  }, [addressesBalances, token, show]);
  useEffect(() => {
    if (show) {
      setForm((draft) => {
        draft.receiveAddress =
          localStorage.getItem(`listingAddress_${accounts[0]}`) || accounts[0];
      });
    }
  }, [accounts, show]);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full  max-w-md transform overflow-hidden rounded-xl bg-[#252524] py-6 text-left align-middle shadow-xl transition-all border border-[rgba(240,236,230,0.2)]">
                <Dialog.Title
                  as="h3"
                  className="text-xl px-6 font-medium text-[#F0ECE6] chill leading-none flex justify-between items-center mb-5"
                >
                  {order?.length ? "Edit price" : "list"}
                  <Image
                    className="cursor-pointer"
                    height={17}
                    src={closeIcon}
                    onClick={() => closeModal(false)}
                    alt=""
                  />
                </Dialog.Title>
                {!order?.length ? (
                  <>
                    <div className="flex items-center px-6">
                      <span className="text-[#F0ECE6] inline-block w-[120px]">
                        Token to list
                      </span>
                      <Select
                        disabled={Boolean(token)}
                        value={form.token}
                        className="w-full"
                        placeholder={`${form.token ? form.token : "Select token"}`}
                        options={
                          token
                            ? []
                            : Object.keys(addressesBalances?.data || 0).map(
                                (item) => ({
                                  label: item.toLocaleLowerCase(),
                                  value: item,
                                }),
                              )
                        }
                        onChange={(e) => {
                          if (!form.token) {
                            setMax(0);
                          }
                          setForm((draft) => {
                            draft.token = e.toString();
                          });
                        }}
                      />
                    </div>
                    <div className="flex items-center px-6 mt-3">
                      <span className="text-[#F0ECE6] inline-block w-[120px]">
                        Bulk buy
                      </span>
                      <div className="flex flex-1 gap-2">
                        <div className="bg-[rgba(240,236,230,0.05)] rounded-md flex-1 flex items-center px-3">
                          <Range
                            value={Number(form.num)}
                            max={max}
                            onChange={(v) => {
                              setForm((draft) => {
                                draft.num = v + "";
                              });
                            }}
                          />
                        </div>
                        <input
                          className="w-[56px] h-[36px] outline-none border-none pl-3 rounded-md text-sm bg-[rgba(207,225,255,0.1)]"
                          type="number"
                          value={form.num}
                          placeholder="0"
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              setForm((draft) => {
                                draft.num = "";
                              });
                            } else if (Number(e.target.value) > max) {
                              setForm((draft) => {
                                draft.num = max.toString();
                              });
                            } else {
                              setForm((draft) => {
                                draft.num = e.target.value;
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={`bg-[#191919] mt-5 px-6 ${
                        utoxsList?.length === 0 ? "min-h-32" : "min-h-16"
                      } max-h-[236px] overflow-y-auto scrollbar relative`}
                    >
                      {isLoading ? (
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          <Loading />
                        </div>
                      ) : (
                        <>
                          {utoxsList?.map((item) => (
                            <div
                              key={item.Utxo}
                              className="py-3 flex justify-between whitespace-nowrap"
                              style={{
                                boxShadow: "0 1px 0 0 rgba(240,236,230,0.1)",
                              }}
                            >
                              <div className="flex-1 leading-none">
                                <div className="text-[#F0ECE6] text-sm font-medium">
                                  {item.CoinId.toLocaleUpperCase()}
                                </div>
                                <div className="mt-1">
                                  <span className="text-[#9A9894]">
                                    Quantity {item.Amount}
                                  </span>
                                  <span className="text-[#AAFF5D] text-xs ml-4">
                                    Price{" "}
                                    {form.uintPrice
                                      ? formatPrice(
                                          Number(form.uintPrice)
                                            .toFixed(2)
                                            .replace(/\.?0*$/, ""),
                                        )
                                      : "--"}{" "}
                                    sats
                                  </span>
                                </div>
                              </div>
                              <div className="flex justify-end items-center gap-4 text-right">
                                <div>
                                  <div className="flex items-center gap-1 justify-end">
                                    <Image src={buckIcon} width={11} alt="" />
                                    <span className="text-sm text-[#FFAF5D] font-medium">
                                      {form.uintPrice
                                        ? satoshisToAmount(
                                            Number(form.uintPrice) *
                                              item.Amount,
                                            true,
                                          )
                                        : "--"}
                                    </span>
                                  </div>
                                  <div className="text-xs text-[#9A9894]">
                                    ${" "}
                                    {form.uintPrice
                                      ? formatNumber(
                                          Number(
                                            satoshisToAmount(
                                              Number(form.uintPrice) *
                                                item.Amount,
                                              true,
                                            ),
                                          ) * usdPrice,
                                        )
                                      : "--"}
                                  </div>
                                </div>
                                <div
                                  className="bg-[rgba(240,236,230,0.1)] w-[28px] h-[28px] rounded flex justify-center items-center cursor-pointer"
                                  onClick={() => {
                                    setRemoveIds((draft) => {
                                      draft.push(item.Utxo);
                                    });
                                    setForm((draft) => {
                                      draft.num = (
                                        Number(draft.num) - 1
                                      ).toString();
                                    });
                                    setMax(max - 1);
                                  }}
                                >
                                  <Image src={deleteIcon} width={12} alt="" />
                                </div>
                              </div>
                            </div>
                          ))}
                          {!utoxsList?.length ? (
                            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#9E9C98] text-sm whitespace-nowrap">
                              Drag the slider to select items
                            </span>
                          ) : null}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="w-[213px] h-[210px] bg-[#161615] rounded-xl mx-auto px-3 pt-[70px]">
                    <div className="text-[42px] md:text-[32px] text-[#F0ECE6] font-semibold text-center leading-none">
                      {order?.[0].Amount}
                    </div>
                    <div className="leading-none text-center mt-4 text-[rgba(240,236,230,0.6)]">
                      <div className="text-lg md:text-sm flex gap-1 justify-center">
                        <span className="text-[#FFAF5D]">
                          {formatPrice(
                            Number(order?.[0].unit_price).toFixed(2) || 0,
                          )}
                        </span>
                        <span>sats</span>
                        <span>/</span>
                        <span>{order?.[0].Ticker.toLocaleUpperCase()}</span>
                      </div>
                      <div className="mt-1 text-base md:text-[13px] leading-none">
                        ${" "}
                        {formatNumber(
                          satoshisToUsd(
                            Number(order?.[0].unit_price),
                            usdPrice,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="px-6 pt-5">
                  <div className="text-[#F0ECE6] text-sm font-medium">
                    Unit price
                    <br />
                    {averagePrice ? (
                      <span className="text-xs text-[#9E9C98] select-none">
                        The latest transaction price is{" "}
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            if (averagePrice) {
                              setForm((draft) => {
                                draft.uintPrice = `${averagePrice}`;
                              });
                            }
                          }}
                        >
                          {averagePrice} sats/$
                          {form.token}.
                        </span>
                      </span>
                    ) : null}
                  </div>
                  <div className="w-full flex items-center px-3 h-[36px] bg-[rgba(240,236,230,0.1)] rounded-md border-[1px solid rgba(240,236,230,0.2)] mt-2">
                    <input
                      type="number"
                      value={form.uintPrice}
                      placeholder={`satoshi per ${token}`}
                      className="flex-1 outline-none border-none text-[#F0ECE6]"
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) {
                          setForm((draft) => {
                            draft.uintPrice = "";
                          });
                        } else {
                          setForm((draft) => {
                            draft.uintPrice = e.target.value;
                          });
                        }
                      }}
                    />
                  </div>
                  {!order?.length ? (
                    <>
                      <div className="text-[#F0ECE6] text-sm font-medium mt-4">
                        Funding receive address
                      </div>
                      <Select
                        autocomplete
                        value={form.receiveAddress}
                        onChange={(e) => {
                          setForm((draft) => {
                            draft.receiveAddress = e.toString();
                          });
                        }}
                        placeholder="Bitcoin address(optional)"
                        options={addressOptions.map((item) => ({
                          tag:
                            item.V1 === accounts[0]
                              ? "The current wallet "
                              : `Used ${item.V2} times `,
                          label: formatAddress(item.V1, 12, -10) || "",
                          value: item.V1,
                        }))}
                      />
                    </>
                  ) : null}
                  {form.receiveAddress
                    ? !validateAddress(form.receiveAddress) && (
                        <div className="text-xs text-[#FF5A00] mt-1">
                          Invalid address
                        </div>
                      )
                    : null}
                  <div className="flex justify-between items-center mt-4">
                    <div>
                      <div className="text-[#F0ECE6] text-sm font-medium leading-none">
                        Total sales value
                      </div>
                      <div className="text-xs mt-2 text-[#9E9C98] leading-none">
                        Maker fee 0% limited time
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="flex items-center gap-1 text-[#FFAF5D] text-lg font-medium leading-none">
                        <Image width={11} src={buckIcon} alt="" />
                        {form.uintPrice && totalUtxos
                          ? satoshisToAmount(
                              totalUtxos * Number(form.uintPrice),
                              true,
                            )
                          : "--"}
                      </div>
                      <div className="text-xs mt-1 leading-none">
                        ${" "}
                        {form.uintPrice
                          ? formatNumber(
                              Number(
                                satoshisToAmount(
                                  Number(form.uintPrice) * totalUtxos,
                                  true,
                                ),
                              ) * usdPrice,
                            )
                          : "--"}
                      </div>
                    </div>
                  </div>
                  {accounts.length && connectedWallet ? (
                    <Button
                      disabled={!utoxsList?.length && !order?.length}
                      loading={isPending || listingPedding || form.loading}
                      className="w-full h-[44px] mt-5"
                      onClick={() => handleConfirm()}
                    >
                      {order?.length ? "Confirm" : "List for sale"}
                    </Button>
                  ) : (
                    <Button
                      loading={isPending || listingPedding || form.loading}
                      className="w-full h-[44px] mt-5"
                      onClick={async () => {
                        if (!accounts.length || !connectedWallet) {
                          await walletContext?.connect();
                          closeModal(false);
                          return;
                        }
                      }}
                    >
                      Connect
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PendingOrder;
