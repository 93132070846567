import { useQuery } from "@tanstack/react-query";
import { RQ_RECOMMENDED } from "../key";
import axios from "axios";
import { envNetwork } from "@/plugins/config";
import { buildMempoolUrl } from "../utils";
interface IProps {
  refetchInterval?: number;
}
const useChainFeeRate = (
  { refetchInterval }: IProps = {
    refetchInterval: 3 * 60 * 1000,
  },
) => {
  const result = useQuery<{
    economyFee: number;
    fastestFee: number;
    halfHourFee: number;
    hourFee: number;
    minimumFee: number;
  }>({
    queryKey: [RQ_RECOMMENDED, envNetwork],
    queryFn: () =>
      axios
        .get(buildMempoolUrl("/api/v1/fees/recommended"))
        .then((res) => res.data),
    refetchInterval,
  });
  return result;
};
export default useChainFeeRate;
