import { Fragment, useContext, useEffect, useMemo } from "react";
import Image from "next/image";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { Dialog, Transition } from "@headlessui/react";
import { Select, Button, Speed } from "@/components";
import { useUsdPrice, useAddressFua } from "@/api/query";
import useGetAccounts from "@/hooks/wallet/useGetAccounts";
import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import {
  formatAddress,
  formatNumber,
  formatPrice,
  satoshisToAmount,
  satoshisToUsd,
  validateAddress,
} from "@/plugins/utils";
import { closeIcon, buckIcon } from "@/assets";
import { MarketContext } from "@/app/Context";
import { useGetPsbt, useOrderStatus, useOrders } from "@/api/mutation";
import { OrderItem } from "@/api/query/getGetOrders";
import { Psbt, Transaction, networks } from "bitcoinjs-lib";
import { envNetwork } from "@/plugins/config";
interface IProps {
  show: boolean;
  order?: OrderItem | null;
  title?: string;
  buttonText?: string;
  closeModal?: (status: boolean) => void;
}
const AcceptOffer = ({
  show,
  order,
  title,
  buttonText,
  closeModal = () => {},
}: IProps) => {
  const [form, setForm] = useImmer({
    fee: {
      value: 0,
      index: 2,
    },
    num: "",
    receiveAddress: "",
    uintPrice: "",
    isPack: false,
    loading: false,
    token: order?.Ticker || "",
  });
  const { wallet } = useWalletInstance();
  const { connectedWallet, wallet: walletContext } = useContext(MarketContext);
  const { mutateAsync, isPending } = useOrderStatus();
  const { mutateAsync: changeOrders, isPending: acceptPedding } = useOrders();
  const { accounts } = useGetAccounts();
  const { data: usdPrice = 0 } = useUsdPrice();
  const { mutateAsync: getPsbt, isPending: psbtPending } = useGetPsbt();
  const { data: listingAddress } = useAddressFua({
    type: "listing",
    address: accounts[0],
    enabled: !!accounts[0] && show,
  });
  const totalUtxos = useMemo(() => {
    if (order?.Amount && order?.unit_price) {
      return Number(order?.Amount) * Number(order?.unit_price);
    }
    return 0;
  }, [order?.Amount, order?.unit_price]);
  const addressOptions = useMemo(() => {
    const addressList = listingAddress?.addresses || [];
    if (addressList.length) {
      return addressList;
    }
    if (accounts.length) {
      return [{ V1: accounts[0], V2: 1 }];
    }
    return [];
  }, [listingAddress]);
  const cancelOrder = async () => {
    if (!order) return;
    const sign = await wallet?.signMessage(order.Id, "ecdsa");
    if (!sign) {
      toast.error("Please unlock your wallet");
      return;
    }
    const toastId = toast.loading("please waiting...");
    try {
      await mutateAsync({
        id: order.Id,
        status: 10,
        signature: sign,
        address: accounts[0],
        gas_fee: form.fee.value,
        refund_address: form.receiveAddress || accounts[0],
      });
      toast.success("Success", {
        id: toastId,
      });
      closeModal(true);
    } catch (error) {
      toast.error("Error", {
        id: toastId,
      });
    }
  };
  const handleSignPsbt = async (
    hex: string,
    sign_flags: boolean[],
    autoFinalized: boolean = true,
  ) => {
    const publicKey = await wallet?.getPublicKey();
    try {
      const toSignInputs: {
        index: number;
        address: string;
        publicKey: string | undefined;
        sighashTypes?: number[];
      }[] = [];
      sign_flags.forEach((item, i) => {
        if (item) {
          if (autoFinalized) {
            toSignInputs.push({
              index: i,
              address: accounts[0],
              publicKey: publicKey,
              sighashTypes: [Transaction.SIGHASH_ALL],
            });
          } else {
            toSignInputs.push({
              index: i,
              address: accounts[0],
              publicKey: undefined,
            });
          }
        }
      });
      try {
        const signPsbt = await wallet?.signPsbt(hex, {
          autoFinalized,
          toSignInputs,
        });
        if (signPsbt) {
          const basePsbt = Psbt.fromHex(signPsbt, {
            network:
              envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
          });
          return basePsbt.toBase64();
        }
        return signPsbt;
      } catch (error) {
        setForm((draft) => {
          draft.loading = false;
        });
        return false;
      }
    } catch (error: any) {
      setForm((draft) => {
        draft.loading = false;
      });
    }
  };
  const handleBuy = async (psbt: string) => {
    const toastId = toast.loading("please waiting...");
    try {
      await changeOrders({
        action: "accept",
        signed_psbt: psbt,
      });
      toast.success("Success", {
        id: toastId,
      });
      localStorage.setItem(`buyAddress_${accounts[0]}`, form.receiveAddress);
    } catch (error: any) {
      toast.error("Error", {
        id: toastId,
      });
    }
  };
  const acceptOrder = async () => {
    if (!order) return;
    setForm((draft) => {
      draft.loading = true;
    });
    try {
      const response = await getPsbt({
        action: "accept",
        orders: [
          {
            unit_price: Number(order.unit_price),
            seller_input_address: accounts[0],
            seller_output_address: form.receiveAddress || accounts[0],
            amount: order.Amount,
            utxo_hash: order.utxo_hash,
            utxo_index: order.utxo_index,
            utxo_value: order.utxo_value,
            buyer_input_address: order.buyer_input_address,
            buyer_output_address: order.buyer_output_address,
            ticker: order.Ticker,
          },
        ],
        gas_fee: form.fee.value,
      });
      const signPsbt = await handleSignPsbt(response.psbt, response.sign_flags);
      setForm((draft) => {
        draft.loading = false;
      });
      if (signPsbt) {
        await handleBuy(signPsbt);
        closeModal(true);
      }
    } catch (error) {
      setForm((draft) => {
        draft.loading = false;
      });
    }
  };
  const handleConfirm = async () => {
    if (title === "Cancel Offer") {
      await cancelOrder();
    } else {
      await acceptOrder();
    }
  };
  useEffect(() => {
    return () => {
      if (!show) return;
      setTimeout(() => {
        setForm((draft) => {
          draft.num = "";
          draft.receiveAddress = "";
          draft.uintPrice = "";
          draft.loading = false;
        });
      }, 0);
    };
  }, [show]);
  useEffect(() => {
    if (show) {
      setForm((draft) => {
        draft.receiveAddress =
          localStorage.getItem(`listingAddress_${accounts[0]}`) || accounts[0];
      });
    }
  }, [accounts, show]);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-[#252524] py-6 text-left align-middle shadow-xl transition-all border border-[rgba(240,236,230,0.2)]">
                <input type="text" className="absolute opacity-0" />
                <Dialog.Title
                  as="h3"
                  className="text-xl px-6 font-medium text-[#F0ECE6] chill leading-none flex justify-between items-center mb-5"
                >
                  {title}
                  <Image
                    className="cursor-pointer"
                    height={17}
                    src={closeIcon}
                    onClick={() => closeModal(false)}
                    alt=""
                  />
                </Dialog.Title>
                {order ? (
                  <div className="w-[213px] h-[210px] bg-[#161615] rounded-xl mx-auto px-3 pt-[70px]">
                    <div className="text-[42px] md:text-[32px] text-[#F0ECE6] font-semibold text-center leading-none">
                      {order.Amount}
                    </div>
                    <div className="leading-none text-center mt-4 text-[rgba(240,236,230,0.6)]">
                      <div className="text-lg md:text-sm flex gap-1 justify-center">
                        <span className="text-[#FFAF5D]">
                          {formatPrice(Number(order.unit_price).toFixed(2))}
                        </span>
                        <span>sats</span>
                        <span>/</span>
                        <span>{order.Ticker.toLocaleUpperCase()}</span>
                      </div>
                      <div className="mt-1 text-base md:text-[13px] leading-none">
                        ${" "}
                        {formatNumber(
                          satoshisToUsd(Number(order.unit_price), usdPrice),
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="px-6 pt-5">
                  <Speed
                    value={form.fee.value}
                    index={form.fee.index}
                    onChange={(v, i) => {
                      setForm((draft) => {
                        draft.fee.value = v;
                        draft.fee.index = i;
                      });
                    }}
                  />
                  <div className="text-[#F0ECE6] text-sm font-medium mt-4">
                    Funding receive address
                  </div>
                  <Select
                    autocomplete
                    value={form.receiveAddress}
                    onChange={(e) => {
                      setForm((draft) => {
                        draft.receiveAddress = e.toString();
                      });
                    }}
                    placeholder="Bitcoin address(optional)"
                    options={addressOptions.map((item) => ({
                      tag:
                        item.V1 === accounts[0]
                          ? "The current wallet "
                          : `Used ${item.V2} times `,
                      label: formatAddress(item.V1, 12, -10) || "",
                      value: item.V1,
                    }))}
                  />
                  {form.receiveAddress
                    ? !validateAddress(form.receiveAddress) && (
                        <div className="text-xs text-[#FF5A00] mt-1">
                          Invalid address
                        </div>
                      )
                    : null}
                  <div className="flex justify-between items-center mt-4">
                    <div>
                      <div className="text-[#F0ECE6] text-sm font-medium leading-none">
                        Total revenue
                      </div>
                      <div className="text-xs mt-2 text-[#9E9C98] leading-none">
                        No service fee
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="flex items-center gap-1 text-[#FFAF5D] text-lg font-medium leading-none">
                        <Image width={11} src={buckIcon} alt="" />
                        {totalUtxos ? satoshisToAmount(totalUtxos, true) : "--"}
                      </div>
                    </div>
                  </div>
                  {accounts.length && connectedWallet ? (
                    <Button
                      loading={
                        psbtPending ||
                        acceptPedding ||
                        isPending ||
                        form.loading
                      }
                      className="w-full h-[44px] mt-5"
                      onClick={() => handleConfirm()}
                    >
                      {buttonText}
                    </Button>
                  ) : (
                    <Button
                      loading={form.loading}
                      className="w-full h-[44px] mt-5"
                      onClick={async () => {
                        if (!accounts.length || !connectedWallet) {
                          await walletContext?.connect();
                          closeModal(false);
                          return;
                        }
                      }}
                    >
                      Connect
                    </Button>
                  )}
                  <div className="text-center text-xs text-[#9E9C98] mt-5">
                    Canceling an offer will also pay transaction gas
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AcceptOffer;
