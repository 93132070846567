import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import toast from "react-hot-toast";
import Image from "next/image";
import { Dialog, Transition } from "@headlessui/react";
import { Psbt, Transaction, networks } from "bitcoinjs-lib";
import { closeIcon, deleteIcon, buckIcon } from "@/assets";
import {
  // calculateUtxoFundsRequired,
  formatAddress,
  formatNumber,
  formatPrice,
  satoshisToAmount,
  satoshisToUsd,
  validateAddress,
} from "@/plugins/utils";
import useGetAccounts from "@/hooks/wallet/useGetAccounts";
import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import { Select, Speed, Button, Loading, Range, Step } from "@/components";
import {
  useUsdPrice,
  useInfiniteGetOrders,
  useAddressFua,
  useOrdersFees,
} from "@/api/query";
import { useGetPsbt, useOrders, useProxy } from "@/api/mutation";
import { MarketContext } from "@/app/Context";
import { envNetwork } from "@/plugins/config";
import { OrderItem } from "@/api/query/getGetOrders";
interface IProps {
  show: boolean;
  token: string;
  utxo?: string;
  id?: string;
  order?: OrderItem | null;
  closeModal?: (status: boolean) => void;
}
const BuyOrder = ({ show, token, order, closeModal = () => {} }: IProps) => {
  const [form, setForm] = useImmer({
    fee: {
      value: 0,
      index: 2,
    },
    current: 0,
    num: "",
    receiveAddress: "",
    uintPrice: "",
    loading: false,
    sleep: 3,
    next: true,
  });
  const [mounted, setMounted] = useState(false);
  const { wallet } = useWalletInstance();
  const { accounts } = useGetAccounts();
  const { connectedWallet, wallet: walletContext } = useContext(MarketContext);
  const { data: orders, isLoading } = useInfiniteGetOrders({
    ticker: token,
    status: [1],
    enabled: !!token && show,
    page_size: 100,
  });
  const { data: buyAddress, refetch: refetchBuyAddress } = useAddressFua({
    type: "buy",
    address: accounts[0],
    enabled: !!accounts[0] && show,
  });
  const { data: fees } = useOrdersFees({
    ticker: token,
    address: accounts[0],
  });
  const { mutateAsync } = useProxy();
  const [max, setMax] = useState(0);
  const { data: usdPrice = 0 } = useUsdPrice();
  const { mutateAsync: getPsbt, isPending } = useGetPsbt();
  const { mutateAsync: changeOrders, isPending: buyPedding } = useOrders();
  const [removeIds, setRemoveIds] = useImmer<string[]>([]);
  const orderList = useMemo(() => {
    if (order) {
      return [order];
    }
    if (orders?.pages[0].orders.length) {
      return orders?.pages[0].orders
        .filter((item) => !removeIds.includes(item.Id))
        .slice(0, Number(form.num));
    }
    return [];
  }, [orders, order, removeIds, form.num]);
  const orderKeysList = useMemo(() => {
    const keys: Record<
      string,
      {
        list: OrderItem[];
        total: number;
      }
    > = {};
    orderList.reduce((acc, item) => {
      const key = item.Id.startsWith("t") ? "t" : "u";
      if (!acc[key]) {
        acc[key] = {
          list: [],
          total: 0,
        };
      }
      acc[key].list.push(item);
      acc[key].total += item.Amount * Number(item.unit_price);
      return acc;
    }, keys);
    return keys;
  }, [orderList]);
  const totalUtxos = useMemo(() => {
    if (order?.Amount) {
      return order.Amount * Number(order.unit_price);
    }
    if (orderList?.length) {
      return orderList.reduce(
        (pre, current) => pre + current.Amount * Number(current.unit_price),
        0,
      );
    }
    return 0;
  }, [orderList, order]);
  const serviceFee = useMemo(() => {
    return totalUtxos * (fees?.buyer || 0) >= 546
      ? totalUtxos * (fees?.buyer || 0)
      : 0;
  }, [totalUtxos, fees]);
  const networkFee = useMemo(() => {
    // if (accounts.length) {
    //   return calculateUtxoFundsRequired(
    //     accounts[0],
    //     orderList.length + 1,
    //     orderList.length + 1 + (serviceFee ? 2 : 1),
    //     form.fee.value,
    //   );
    // }
    return 0;
  }, [totalUtxos, orderList, accounts[0], form.fee.value, serviceFee]);
  const addressOptions = useMemo(() => {
    const addressList = buyAddress?.addresses || [];
    if (addressList.length) {
      return addressList;
    }
    if (accounts.length) {
      return [{ V1: accounts[0], V2: 1 }];
    }
    return [];
  }, [accounts, buyAddress]);
  const handleSignPsbt = async (
    hex: string,
    sign_flags: boolean[],
    autoFinalized: boolean = true,
  ) => {
    const publicKey = await wallet?.getPublicKey();
    try {
      const toSignInputs: {
        index: number;
        address: string;
        publicKey: string | undefined;
        sighashTypes?: number[];
      }[] = [];
      sign_flags.forEach((item, i) => {
        if (item) {
          if (autoFinalized) {
            toSignInputs.push({
              index: i,
              address: accounts[0],
              publicKey: publicKey,
              sighashTypes: [Transaction.SIGHASH_ALL],
            });
          } else {
            toSignInputs.push({
              index: i,
              address: accounts[0],
              publicKey: undefined,
            });
          }
        }
      });
      try {
        const signPsbt = await wallet?.signPsbt(hex, {
          autoFinalized,
          toSignInputs,
        });
        if (signPsbt) {
          const basePsbt = Psbt.fromHex(signPsbt, {
            network:
              envNetwork === "livenet" ? networks.bitcoin : networks.testnet,
          });
          return basePsbt.toBase64();
        }
        return signPsbt;
      } catch (error) {
        setForm((draft) => {
          draft.loading = false;
        });
        return false;
      }
    } catch (error: any) {
      setForm((draft) => {
        draft.loading = false;
      });
    }
  };
  const handleBuy = async (psbt: string) => {
    const toastId = toast.loading("please waiting...");
    try {
      await changeOrders({
        action: "buy",
        signed_psbt: psbt,
      });
      toast.success("Success", {
        id: toastId,
      });
      localStorage.setItem(`buyAddress_${accounts[0]}`, form.receiveAddress);
      return true;
    } catch (error: any) {
      toast.error("Error", {
        id: toastId,
      });
      if (error.code === 500) {
        // If there is a rush, allow him to continue trading
        return true;
      }
      return false;
    }
  };
  /**
   * current market
   * @returns
   */
  const submitCurrentMarket = async () => {
    setForm((draft) => {
      draft.loading = true;
    });
    try {
      const response = await getPsbt({
        action: "buy",
        orders: orderKeysList.u.list.map((item) => ({
          unit_price: Number(item.unit_price),
          seller_input_address: item.seller_input_address,
          seller_output_address: item.seller_output_address,
          utxo_hash: item.utxo_hash,
          utxo_index: item.utxo_index,
          utxo_value: item.utxo_value,
          buyer_input_address: accounts[0],
          buyer_output_address: form.receiveAddress || accounts[0],
          ticker: item.Ticker,
        })),
        gas_fee: form.fee.value,
      });
      refetchBuyAddress();
      const signPsbt = await handleSignPsbt(response.psbt, response.sign_flags);
      setForm((draft) => {
        draft.loading = false;
      });
      if (signPsbt) {
        const result = await handleBuy(signPsbt);
        return result;
      }
      return false;
    } catch (error: any) {
      if (error.code === 400) {
        // The balance is insufficient. Go to another market
        return true;
      }
      setForm((draft) => {
        draft.loading = false;
      });
      return false;
    }
  };
  const placeOrder = async (psbt: string) => {
    const toastId = toast.loading("please waiting...");
    try {
      const response = await mutateAsync({
        cmd: "request.post",
        url: "/api/trpc/trading.mergePsbts?batch=1",
        postData: {
          "0": {
            json: {
              ids: orderKeysList.t.list.map((item) => item.Id.slice(1)),
              feeRate: form.fee.value,
              signedPsbt: psbt,
              receiveAddress: form.receiveAddress || accounts[0],
              version: "1.1",
              wallet:
                connectedWallet &&
                connectedWallet.charAt(0).toUpperCase() +
                  connectedWallet.slice(1),
            },
          },
        },
      });
      await changeOrders({
        action: "notify",
        signed_psbt: psbt,
        tx_hash: response[0].result.data.json.txid,
      });
      toast.success("Success", {
        id: toastId,
      });
      return true;
    } catch (error) {
      toast.error("Error", {
        id: toastId,
      });
      return false;
    }
  };
  const otherMarket = async () => {
    setForm((draft) => {
      draft.loading = true;
    });
    try {
      const response = await mutateAsync({
        cmd: "request.post",
        url: "/api/trpc/trading.parepareUnsignedBuyingPSBT?batch=1",
        postData: {
          "0": {
            json: {
              ids: orderKeysList.t.list.map((item) => item.Id.slice(1)),
              feeRate: form.fee.value,
              receiveAddress: form.receiveAddress || accounts[0],
              version: "1.1",
            },
          },
        },
      });
      const signFlags: boolean[] = [];
      const end = response[0].result.data.json.signingIndexes.at(-1);
      const start = response[0].result.data.json.signingIndexes.at(0);
      for (let i = 0; i <= end - start; i++) {
        if (i === start || i === end) {
          signFlags.push(true);
        } else {
          signFlags.push(false);
        }
      }
      const signPsbt = await handleSignPsbt(
        response[0].result.data.json.unsigned_buying_psbt,
        signFlags,
        false,
      );
      if (signPsbt) {
        const result = await placeOrder(signPsbt);
        setForm((draft) => {
          draft.loading = false;
        });
        return result;
      }
      return false;
    } catch (error: any) {
      setForm((draft) => {
        draft.loading = false;
      });
      if (error.code === 412) {
        if (
          error.msg?.[0]?.error?.json?.message?.includes("Insufficient balance")
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
  };
  const handleConfirm = async () => {
    if (!accounts.length || !connectedWallet) {
      await walletContext?.connect();
      closeModal(false);
      return;
    }
    if (!form.fee.value) {
      toast.error("Please enter gas", {
        id: "market",
      });
      return;
    }
    if (!validateAddress(form.receiveAddress || accounts[0])) {
      toast.error("Invalid address", {
        id: "market",
      });
      return;
    }
    let result = true;
    if (form.current === 0 && orderKeysList.t?.total) {
      result = await otherMarket();
      if (!result) return;
      if (orderKeysList.u?.total) {
        setForm((draft) => {
          draft.current += 1;
          draft.sleep = 3;
          draft.next = true;
        });
        return;
      }
    }
    if (orderKeysList.u?.total) {
      setForm((draft) => {
        draft.next = false;
      });
      const res = await submitCurrentMarket();
      setForm((draft) => {
        draft.current = res ? 0 : draft.current;
        draft.sleep = 3;
      });
      if (!res) return;
    }
    closeModal(true);
  };
  useEffect(() => {
    return () => {
      if (!show) return;
      setTimeout(() => {
        setMounted(false);
        setForm((draft) => {
          draft.num = "";
          draft.current = 0;
          draft.sleep = 3;
          draft.next = true;
          draft.receiveAddress = "";
          draft.uintPrice = "";
          draft.fee = {
            value: 0,
            index: 2,
          };
          draft.loading = false;
        });
        setRemoveIds([]);
      }, 0);
    };
  }, [show]);
  useEffect(() => {
    if (orders?.pages[0].total && show) {
      setMax(orders?.pages[0].total > 100 ? 100 : orders?.pages[0].total);
    }
  }, [orders, show]);
  useEffect(() => {
    if (!mounted && orders?.pages[0].total) {
      setMounted(true);
      setForm((draft) => {
        draft.num =
          orders?.pages[0].total > 20
            ? "20"
            : orders?.pages[0].total.toString();
      });
    }
  }, [orders?.pages[0].total, mounted]);
  useEffect(() => {
    if (show) {
      setForm((draft) => {
        draft.receiveAddress =
          localStorage.getItem(`buyAddress_${accounts[0]}`) || accounts[0];
      });
    }
  }, [accounts, show]);
  useEffect(() => {
    if (form.current && form.next) {
      if (form.sleep > 0) {
        const timerId = setTimeout(() => {
          setForm((draft) => {
            draft.sleep = draft.sleep - 1;
          });
        }, 1000);
        return () => clearTimeout(timerId);
      } else if (form.sleep === 0) {
        handleConfirm();
      }
    }
  }, [
    form.current,
    form.sleep,
    form.next,
    orderKeysList,
    connectedWallet,
    accounts,
    show,
  ]);
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-sm">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-[#252524] pt-6 text-left align-middle shadow-xl transition-all border border-[rgba(240,236,230,0.2)]">
                <Dialog.Title
                  as="h3"
                  className="text-xl px-6 font-medium text-[#F0ECE6] chill leading-none flex justify-between items-center mb-5"
                >
                  buy
                  <Image
                    className="cursor-pointer"
                    height={17}
                    src={closeIcon}
                    onClick={() => closeModal(false)}
                    alt=""
                  />
                </Dialog.Title>
                <input type="text" className="absolute opacity-0" />
                {!order ? (
                  <>
                    {!token ? (
                      <div className="flex items-center px-6">
                        <span className="text-[#F0ECE6] inline-block w-[120px]">
                          Token to buy
                        </span>
                        <Select
                          disabled={Boolean(token)}
                          className="w-full"
                          placeholder={token}
                          options={[]}
                          value={0}
                        />
                      </div>
                    ) : null}
                    <div className="flex items-center px-6 mt-3">
                      <span className="text-[#F0ECE6] inline-block w-[120px]">
                        Bulk buy
                      </span>
                      <div className="flex flex-1 gap-2">
                        <div className="bg-[rgba(240,236,230,0.05)] rounded-md flex-1 flex items-center px-3">
                          <Range
                            value={Number(form.num)}
                            max={max}
                            disabled={form.loading || form.current > 0}
                            onChange={(v) => {
                              setForm((draft) => {
                                draft.num = v + "";
                              });
                            }}
                          />
                        </div>
                        <input
                          className="w-[56px] h-[36px] outline-none border-none pl-3 rounded-md text-sm bg-[rgba(207,225,255,0.1)]"
                          type="number"
                          value={form.num}
                          placeholder="0"
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) {
                              setForm((draft) => {
                                draft.num = "";
                              });
                            } else if (Number(e.target.value) > max) {
                              setForm((draft) => {
                                draft.num = max.toString();
                              });
                            } else {
                              setForm((draft) => {
                                draft.num = e.target.value;
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="relative mt-5 overflow-hidden">
                      <div
                        className={`bg-[#191919] px-6 ${
                          orderList?.length === 0 ? "min-h-32" : "min-h-16"
                        } max-h-[236px] ${form.loading ? "overflow-y-auto" : "overflow-y-auto"} scrollbar relative`}
                      >
                        {isLoading ? (
                          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                            <Loading />
                          </div>
                        ) : (
                          <>
                            {orderList?.map((item) => (
                              <div
                                key={item.Id}
                                className="py-3 flex justify-between whitespace-nowrap"
                                style={{
                                  boxShadow: "0 1px 0 0 rgba(240,236,230,0.1)",
                                }}
                              >
                                <div className="flex-1 leading-none">
                                  <div className="text-[#F0ECE6] text-sm font-medium">
                                    {item.Ticker.toLocaleUpperCase()}
                                  </div>
                                  <div className="mt-1">
                                    <span className="text-[#9A9894]">
                                      Quantity {item.Amount}
                                    </span>
                                    <span className="text-[#AAFF5D] text-xs ml-4">
                                      Price{" "}
                                      {formatPrice(
                                        Number(item.unit_price)
                                          .toFixed(2)
                                          .replace(/\.?0*$/, ""),
                                      )}
                                      sats
                                    </span>
                                  </div>
                                </div>
                                <div className="flex justify-end items-center gap-4 text-right">
                                  <div>
                                    <div className="flex items-center gap-1 justify-end">
                                      <Image src={buckIcon} width={11} alt="" />
                                      <span className="text-sm text-[#FFAF5D] font-medium">
                                        {satoshisToAmount(
                                          Number(item.unit_price) * item.Amount,
                                          true,
                                        )}
                                      </span>
                                    </div>
                                    <div className="text-xs text-[#9A9894]">
                                      ${" "}
                                      {formatNumber(
                                        Number(
                                          satoshisToAmount(
                                            Number(item.unit_price) *
                                              item.Amount,
                                            true,
                                          ),
                                        ) * usdPrice,
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="bg-[rgba(240,236,230,0.1)] w-[28px] h-[28px] rounded flex justify-center items-center cursor-pointer"
                                    onClick={() => {
                                      setRemoveIds((draft) => {
                                        draft.push(item.Id);
                                      });
                                      setForm((draft) => {
                                        draft.num = (
                                          Number(draft.num) - 1
                                        ).toString();
                                      });
                                      setMax(max - 1);
                                    }}
                                  >
                                    <Image src={deleteIcon} width={12} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                            {orderList?.length === 0 ? (
                              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[#9E9C98] text-sm whitespace-nowrap">
                                Drag the slider to select items
                              </span>
                            ) : null}
                          </>
                        )}
                      </div>
                      {form.loading || form.current > 0 ? (
                        <div className="w-full h-full absolute bg-[#1A1A19] opacity-90 left-0 top-0">
                          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                            <Loading type={3} />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className="w-[213px] h-[210px] bg-[#161615] rounded-xl mx-auto px-3 pt-[70px]">
                    <div className="text-[42px] md:text-[32px] text-[#F0ECE6] font-semibold text-center leading-none">
                      {order.Amount}
                    </div>
                    <div className="leading-none text-center mt-4 text-[rgba(240,236,230,0.6)]">
                      <div className="text-lg md:text-sm flex gap-1 justify-center">
                        <span className="text-[#FFAF5D]">
                          {formatPrice(Number(order.unit_price).toFixed(2))}
                        </span>
                        <span>sats</span>
                        <span>/</span>
                        <span>{token.toLocaleUpperCase()}</span>
                      </div>
                      <div className="mt-1 text-base md:text-[13px] leading-none">
                        ${" "}
                        {formatNumber(
                          satoshisToUsd(Number(order.unit_price), usdPrice),
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="px-6 pt-5">
                  <Speed
                    value={form.fee.value}
                    index={form.fee.index}
                    onChange={(v, i) => {
                      setForm((draft) => {
                        draft.fee.value = v;
                        draft.fee.index = i;
                      });
                    }}
                  />
                  <div className="text-[#F0ECE6] text-sm font-medium mt-4">
                    Item receive address
                  </div>
                  <Select
                    autocomplete
                    value={form.receiveAddress}
                    onChange={(e) => {
                      setForm((draft) => {
                        draft.receiveAddress = e.toString();
                      });
                    }}
                    placeholder="Bitcoin address(optional)"
                    options={addressOptions.map((item) => ({
                      tag:
                        item.V1 === accounts[0]
                          ? "The current wallet "
                          : `Used ${item.V2} times `,
                      label: formatAddress(item.V1, 12, -10) || "",
                      value: item.V1,
                    }))}
                  />
                  {form.receiveAddress
                    ? !validateAddress(form.receiveAddress) && (
                        <div className="text-xs text-[#FF5A00] mt-1">
                          Invalid address
                        </div>
                      )
                    : null}
                  <div className="mt-4 flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="text-xs text-[#9E9C98] leading-none">
                        Total cost
                      </div>
                      <div className="flex items-center justify-end gap-1 text-[#FFAF5D] text-lg font-medium leading-none">
                        <Image width={11} src={buckIcon} alt="" />
                        {satoshisToAmount(
                          totalUtxos + serviceFee + networkFee,
                          true,
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="text-xs text-[#9E9C98] leading-none">
                        {serviceFee
                          ? `Taker fee ${(fees?.buyer || 0) * 100}%, transaction fee not included`
                          : "No service fee"}
                      </div>
                      {serviceFee ? (
                        <div className="text-xs leading-none">
                          ${" "}
                          {formatNumber(
                            Number(satoshisToAmount(serviceFee, true)) *
                              usdPrice,
                            3,
                          )}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="flex justify-between items-center">
                      <div className="text-xs text-[#9E9C98] leading-none">
                        Network fee
                      </div>
                      <div className="text-xs leading-none">
                        $
                        {formatNumber(
                          Number(satoshisToAmount(networkFee, true)) * usdPrice,
                          3,
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="bg-[#191919] mt-5 p-6">
                  {Object.keys(orderKeysList).length > 1 ? (
                    <Step
                      current={form.current}
                      items={[
                        {
                          title: "Other Market",
                          description: `Buy $${formatNumber(
                            Number(
                              satoshisToAmount(orderKeysList.t?.total, true),
                            ) * usdPrice,
                            3,
                          )}`,
                        },
                        {
                          title: "Unibit Market",
                          description: `Buy $${formatNumber(
                            Number(
                              satoshisToAmount(orderKeysList.u?.total, true),
                            ) * usdPrice,
                            3,
                          )}`,
                        },
                      ]}
                    />
                  ) : null}
                  <Button
                    disabled={!orderList?.length}
                    loading={isPending || buyPedding || form.loading}
                    className="w-full h-[44px]"
                    onClick={() => handleConfirm()}
                  >
                    <div className="w-full flex justify-between">
                      <div className="mx-auto">
                        {accounts.length && connectedWallet
                          ? `Buy now${Object.keys(orderKeysList).length > 1 ? `(${form.current + 1}/${Object.keys(orderKeysList).length})` : ""}`
                          : "Connect"}
                      </div>
                      {form.current && form.sleep && form.next ? (
                        <div>{form.sleep}s</div>
                      ) : null}
                    </div>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BuyOrder;
