import axios from "axios";
// import toast from "react-hot-toast";
// import { Base64 } from "js-base64";
// import { load } from "cheerio";
import { useMutation } from "@tanstack/react-query";
// import Cookies from "js-cookie";

interface Params {
  cmd: "request.get" | "request.post";
  session?: string;
  url: string;
  cookies?: { name: string; value: string }[];
  proxy?: { url: string };
  postData?: Record<string, any> | string;
}
// const formatHtmlToJson = (text: string) => {
//   const $ = load(text);
//   const preTag = $("pre");
//   const jsonText = preTag.text();
//   const jsonData = JSON.parse(jsonText);
//   return jsonData;
// };
const queryString = (params: Record<string, any>) => {
  return Object.keys(params)
    .map(
      (key) =>
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(JSON.stringify(params[key])),
    )
    .join("&");
};
const UseProxy = () => {
  const mutation = useMutation<any, unknown, Params>({
    mutationFn: (_params) => {
      const params = _params;
      const fn = params.cmd === "request.get" ? axios.get : axios.post;
      if (params.cmd === "request.get") {
        params.url = `${params.url}?${queryString(params.postData as Record<string, any>)}`;
      }
      return fn(
        params.url,
        params.cmd === "request.post" ? params.postData : {},
      ).then((res) => {
        const data = res.data;
        return data;
      });
    },
  });
  return mutation;
};
export default UseProxy;
