import { useQuery } from "@tanstack/react-query";
import { RQ_ADDRESSTOKENS } from "../key";
import axios from "axios";
export interface CoinsResultItem {
  balance: number;
  id: string;
  items: number;
  unit_price: number;
  value: number;
}
interface Response {
  data: {
    list: CoinsResultItem[];
    total: number;
    total_price: number;
  } & CoinsResultItem;
  result: boolean;
}
interface IProps {
  page?: number;
  page_size?: number;
  address: string;
  id?: string;
}
const useAddressesTokens = ({
  page = 1,
  page_size = 30,
  address,
  id,
}: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_ADDRESSTOKENS, page, page_size, address, id],
    queryFn: async () => {
      return axios
        .get(`/api/v1/addresses/${address}/coins${id ? `/${id}` : ""}`, {
          params: {
            page,
            page_size,
            address,
          },
        })
        .then((res) => res.data);
    },
    enabled: !!address,
  });
  return result;
};
export default useAddressesTokens;
