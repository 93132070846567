import axios from "axios";
import { useMutation } from "@tanstack/react-query";
interface ListingOrder {
  unit_price: number;
  seller_input_address: string;
  seller_output_address: string;
  utxo_hash: string;
  utxo_index: number;
  utxo_value: number;
}

interface BuyOrder extends ListingOrder {
  ticker?: string;
  amount?: number;
  buyer_input_address: string;
  buyer_output_address: string;
}

interface OfferOrder {
  ticker: string;
  unit_price: number;
  amount: number;
  buyer_input_address: string;
  buyer_output_address: string;
}
interface ListingParams {
  action: "listing";
  orders: ListingOrder[];
}

interface BuyParams {
  action: "buy" | "accept";
  orders: BuyOrder[];
  gas_fee: number;
}
interface OfferParams {
  action: "offer";
  orders: OfferOrder[];
  gas_fee: number;
}
type Params = ListingParams | BuyParams | OfferParams;

interface Response {
  sign_flags: boolean[];
  psbt: string;
}
const useGetPsbt = () => {
  const mutation = useMutation<Response, unknown, Params>({
    mutationFn: (params) =>
      axios.post("/market/v1/psbt", params).then((res) => res?.data),
  });
  return mutation;
};
export default useGetPsbt;
