import { Fragment, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { Listbox, Transition } from "@headlessui/react";
import { arrowDownSelectIcon, closeIcon } from "@/assets";
import { formatAddress } from "@/plugins/utils";
import { useGetAccounts } from "@/hooks";

interface IProps {
  value: string | number;
  className?: string;
  placeholder?: string;
  options: { value: string | number; label: string; tag?: string }[];
  clear?: boolean;
  disabled?: boolean;
  autocomplete?: boolean;
  onChange?: (value: IProps["value"]) => void;
}
const Select = ({
  value,
  options,
  placeholder,
  className,
  clear,
  autocomplete = false,
  disabled = false,
  onChange,
}: IProps) => {
  const { accounts } = useGetAccounts();
  const [v, setV] = useState<IProps["value"] | undefined>(value);
  const [label, setLabel] = useState<string>("");
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const listboxRef = useRef<HTMLDivElement>(null);
  const handleChange = (val: IProps["value"]) => {
    if (autocomplete) {
      setText(val as string);
    }
    setV(val);
  };
  const handleLabel = (val?: IProps["value"]) => {
    setLabel(options.find((item) => item.value === val)?.label || "");
  };
  const [hover, setHover] = useState(false);
  useEffect(() => {
    handleLabel(value);
    setV(value);
  }, [value]);

  useEffect(() => {
    handleLabel(v);
    if (v !== undefined) {
      onChange?.(v);
    }
  }, [v]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (listboxRef.current && !listboxRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [show]);
  return (
    <Listbox defaultValue={v} onChange={handleChange} disabled={disabled}>
      <div className="relative flex-1" ref={listboxRef}>
        {autocomplete ? (
          <div className="w-full flex items-center px-3 h-[36px] bg-[rgba(240,236,230,0.1)] rounded-md border-[1px solid rgba(240,236,230,0.2)] mt-2 relative select-none">
            {v ? (
              <>
                <span className="px-2 py-[2px] text-xs text-[#F0ECE6] bg-zinc-900 rounded-md">
                  {v === accounts[0]
                    ? "The current wallet " +
                      formatAddress((v || "").toString(), 10, -12)
                    : formatAddress((v || "").toString(), 10, -12)}
                </span>
                <div
                  className="absolute right-4 p-1 cursor-pointer z-10"
                  onClick={() => {
                    setShow(false);
                    setText("");
                    setV("");
                  }}
                >
                  <Image height={10} src={closeIcon} alt="" />
                </div>
              </>
            ) : (
              <input
                type="text"
                placeholder={placeholder}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                className="flex-1 outline-none border-none text-[#F0ECE6]"
                onFocus={() => setShow(true)}
                onBlur={() => {
                  setV(text);
                  setTimeout(() => {
                    setShow(false);
                  }, 100);
                }}
              />
            )}
          </div>
        ) : (
          <Listbox.Button
            className={`relative flex justify-between items-center cursor-pointer rounded-md bg-[rgba(240,236,230,0.1)] text-[#C7C4BF] px-3 py-[6px] border-[rgba(240,236,230,0.1)] border sm:text-sm h-8 ${className}`}
            onClick={(e) => {
              e.stopPropagation();
              setShow(!show);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            onMouseEnter={() => {
              setHover(true);
            }}
          >
            <span className="block truncate leading-none">
              {label || placeholder || "Select"}
            </span>
            {disabled ? null : (
              <span className="ml-[15px]">
                <Image
                  src={closeIcon}
                  width={9}
                  className={`${clear && hover && value ? "block" : "hidden"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange?.("");
                  }}
                  alt=""
                />
                <Image
                  src={arrowDownSelectIcon}
                  width={12}
                  className={`${clear && hover && value ? "hidden" : "block"}`}
                  alt=""
                />
              </span>
            )}
          </Listbox.Button>
        )}
        <Transition
          show={show}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto bg-[#252524] border border-[rgba(240,236,230,0.2)] rounded-lg focus:outline-none sm:text-sm">
            {options.map((d, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `${
                    active ? "bg-[rgba(255,255,255,0.05)]" : ""
                  } flex w-full items-center h-8 text-[#C7C4BF] cursor-pointer px-4 text-sm font-medium`
                }
                onClick={() => {
                  setShow(false);
                }}
                value={d.value}
              >
                {({ selected }) => (
                  <>
                    {d.tag ? (
                      <span className="px-2 py-[2px] text-xs text-[#F0ECE6] bg-zinc-600 rounded-md mr-1">
                        {d.tag}
                      </span>
                    ) : null}
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {d.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
