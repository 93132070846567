import { useContext, useEffect, useState } from "react";
import useWalletInstance, { NetWork } from "@/hooks/wallet/useWalletInstance";
import { envNetwork } from "@/plugins/config";
import { MarketContext } from "@/app/Context";

const useNetwork = () => {
  const { wallet: walletInstance } = useWalletInstance();
  const [network, setNetwork] = useState<NetWork | undefined>();
  const { wallet } = useContext(MarketContext);
  const getNetwork = async () => {
    const res = await walletInstance?.getNetwork();
    setNetwork(res);
  };
  const handlerNetWork = (net: NetWork) => {
    if (net !== envNetwork) {
      wallet?.setConnect(false);
    }
    setNetwork(net);
  };
  useEffect(() => {
    getNetwork();
    walletInstance?.on("networkChanged", handlerNetWork);
    return () => {
      walletInstance?.removeListener("networkChanged", handlerNetWork);
    };
  }, [walletInstance]);
  return network;
};
export default useNetwork;
