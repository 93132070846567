import { ReactNode, useMemo, useState } from "react";
import Image from "next/image";
import { Loading } from "@/components";
import { arrowDownSelectIcon, arrowLeftIcon, arrowRightIcon } from "@/assets";
export interface Columns<T> {
  title: string;
  dataIndex: string;
  sort?: boolean;
  align?: "left" | "right" | "center";
  onSort?: (sort: boolean) => void;
  render?: (item: T, index: number) => ReactNode;
}
interface IProps<T> {
  columns: Columns<T>[];
  dataSource?: T[];
  keyIndex: string;
  loading?: boolean;
  pagination?: {
    current: number;
    pageSize: number;
    total: number;
    /**
     * Whether to hide the pager when there is only one page
     */
    hideOnSinglePage?: boolean;
    onChange?: (page: number, pageSize: number) => void;
  };
  onRow?: {
    onClick: (record: T, index: number) => void;
  };
}
const TableList = <T,>({
  columns,
  loading,
  keyIndex,
  dataSource = [],
  pagination,
  onRow,
}: IProps<T>) => {
  const totalPages = useMemo(() => {
    if (!pagination) return 0;
    return Math.ceil(pagination.total / pagination.pageSize);
  }, [pagination?.total, pagination?.pageSize]);
  const [page, setPage] = useState("");
  const visiblePageRange = 7;
  const generatePageNumbers = () => {
    if (!pagination) return [];
    const pageNumbers = [];

    if (totalPages <= visiblePageRange) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(
        1,
        pagination?.current - Math.floor(visiblePageRange / 2),
      );
      const endPage = Math.min(totalPages, startPage + visiblePageRange - 1);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push(null);
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push(null);
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };
  return (
    <>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="relative">
            <tr className="text-[13px] text-[rgba(240,236,230,0.6)] border-none h-[36px]">
              {columns.map((item, index) => (
                <th
                  key={item.dataIndex}
                  className={`text-left items-center ${
                    index === 0
                      ? "sticky left-[-3px] top-0 z-10 bg-[#0F0F0F]"
                      : ""
                  } ${item.onSort ? "cursor-pointer" : ""}`}
                  style={{
                    borderBottom: "1px solid rgba(240,236,230,0.1)",
                  }}
                  onClick={() => {
                    item.onSort?.(!item.sort);
                  }}
                >
                  <span
                    className={`flex items-center ${item.align === "right" ? "justify-end" : ""} gap-1 ${
                      item.sort !== undefined ? "select-none" : ""
                    }`}
                  >
                    {item.title}
                    {item.sort !== undefined ? (
                      <Image
                        src={arrowDownSelectIcon}
                        width={12}
                        className={`${
                          !item.sort ? "rotate-180" : ""
                        } transition-[1s]`}
                        alt=""
                      />
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm text-white">
            {(dataSource || [])?.map((item, rowIndex) => (
              <tr
                key={(item as { [key: string]: any })[keyIndex]}
                className="bg-[#0F0F0F]"
                style={{
                  borderBottom: "1px solid rgba(240,236,230,0.1)",
                }}
                onClick={() => {
                  onRow?.onClick(item, rowIndex);
                }}
              >
                {columns.map((info, index) => (
                  <td
                    key={info.title}
                    className={`text-sm h-16 ${info.align === "right" ? "text-right" : ""} ${
                      index === 0
                        ? "sticky left-[-3px] z-10 bg-[#0F0F0F]"
                        : " whitespace-nowrap"
                    } ${onRow?.onClick ? "cursor-pointer" : ""}`}
                  >
                    {info.render
                      ? info.render(item, index)
                      : (item as Record<string, any>)[info.dataIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {loading ? (
          <div className="p-5 text-center text-white">
            <Loading />
          </div>
        ) : null}
      </div>
      {pagination && totalPages > 1 ? (
        <div className="flex justify-end items-center gap-2 py-6">
          <button
            className={`w-[28px] h-[28px] text-[13px] rounded-md flex items-center justify-center bg-[rgba(240,236,230,0.1)] text-[rgba(240,236,230,0.5)] ${
              pagination.current <= 1 ? "disabled" : ""
            }`}
            onClick={() => {
              if (pagination.current > 1) {
                pagination.onChange?.(
                  pagination.current - 1,
                  pagination.pageSize,
                );
              }
            }}
          >
            <Image src={arrowLeftIcon} width={12} alt="" />
          </button>
          {generatePageNumbers().map((item, index) => (
            <button
              className={`min-w-[28px] md:px-1 h-[28px] text-[13px] rounded-md flex items-center justify-center overflow-hidden ${
                pagination.current === item
                  ? "bg-[#AAFF5D] text-black"
                  : "bg-[rgba(240,236,230,0.1)] text-[rgba(240,236,230,0.5)]"
              } ${
                item === null
                  ? "disabled"
                  : "hover:bg-[#AAFF5D] hover:text-black"
              }`}
              key={index}
              onClick={() => {
                if (item === null) return;
                pagination.onChange?.(item, pagination.pageSize);
              }}
            >
              {item === null ? "..." : item}
            </button>
          ))}
          <button
            className={`w-[28px] h-[28px] text-[13px] rounded-md flex items-center justify-center bg-[rgba(240,236,230,0.1)] text-[rgba(240,236,230,0.5)] ${
              pagination.current >= totalPages ? "disabled" : ""
            }`}
            onClick={() => {
              if (pagination.current < totalPages) {
                pagination.onChange?.(
                  pagination.current + 1,
                  pagination.pageSize,
                );
              }
            }}
          >
            <Image src={arrowRightIcon} width={12} alt="" />
          </button>
          <span className="text-[13px] mx-1 text-[rgba(240,236,230,0.5)] align-middle">
            page
          </span>
          <button className="w-10 h-[28px] text-[13px] rounded-md flex items-center justify-center bg-[rgba(240,236,230,0.1)] text-[rgba(240,236,230,0.5)]">
            <input
              type="text"
              value={page}
              className="w-full bg-transparent outline-none text-center"
              onKeyDown={(e) => {
                if (e.key === "Enter" && Number(page)) {
                  pagination?.onChange?.(Number(page), pagination.pageSize);
                }
              }}
              onChange={(e) => {
                if (Number(e.target.value) > totalPages) {
                  setPage(totalPages + "");
                } else {
                  setPage(e.target.value);
                }
              }}
            />
          </button>
        </div>
      ) : null}
    </>
  );
};
export default TableList;
