const TokenSkeleton = () => {
  return (
    <div className="bg-[rgba(0,0,0,0.4)] rounded-xl border border-[rgba(240,236,230,0.2)]">
      <div className="pt-4 px-3 bg-[rgba(240,236,230,0.1)] rounded-t-xl">
        <div className="flex justify-between">
          <div className="skeleton py-[3px] px-[6px] w-20 rounded"></div>
          <div className="skeleton w-6 h-6 rounded-full shrink-0"></div>
        </div>
        <div className="skeleton h-16 w-full rounded mt-6" />
        <div className="text-[#7F7D7A] py-5 leading-none text-center">
          <div className="skeleton h-4 w-28 rounded mx-auto mb-2"></div>
          <div className="skeleton h-4 w-12 rounded mx-auto"></div>
        </div>
      </div>
      <div className="px-4 rounded-b-xl">
        <div className="flex justify-between items-center font-medium pt-4">
          <div className="skeleton h-5 flex-1 rounded" />
        </div>
        <div className="flex justify-between gap-2 mt-4 pb-4">
          <div className="skeleton h-8 flex-1 rounded-md" />
          <div className="skeleton w-8 h-8 rounded-md" />
        </div>
      </div>
    </div>
  );
};

export default TokenSkeleton;
