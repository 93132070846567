import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { RQ_ORDERS } from "../key";
import axios from "axios";
import { useRef } from "react";
import { Events } from "./getAddressOrders";
export interface OrderItem {
  Amount: number;
  CreatedAt: string;
  Id: string;
  Protocol: number;
  Psbt: string;
  Status: keyof typeof Events;
  Ticker: string;
  TotalPrice: number;
  TxHash: string;
  UpdatedAt: string;
  buyer_input_address: string;
  buyer_output_address: string;
  seller_input_address: string;
  seller_output_address: string;
  unit_price: string;
  utxo_hash: string;
  utxo_index: number;
  utxo_value: number;
}
interface Response {
  orders: OrderItem[];
  total: number;
}
export interface IProps {
  ticker: string;
  status: number[]; //1 - listing, 2 - canceled, 3 - pending, 4 - success, 5 - failed, 6 - sold, 7 - bought, 8 - all, 9 - offerd, 10 -withdrawed
  page?: number;
  page_size?: number;
  refetchInterval?: number;
  order_by?: "unit_price" | "amount";
  dir?: "asc" | "desc";
  enabled?: boolean;
  infinite?: boolean;
}

const useInfiniteGetOrders = ({
  ticker,
  status,
  order_by = "unit_price",
  dir = "asc",
  enabled,
  page_size = 30,
  refetchInterval,
}: IProps) => {
  const pageRef = useRef(1);
  const result = useInfiniteQuery<any, Error, InfiniteData<Response>>({
    queryKey: [RQ_ORDERS, ticker, status, order_by, dir],
    queryFn: ({ pageParam = 0 }) => {
      if (Number(pageParam) > 0) {
        pageRef.current = Number(pageParam);
      }
      return axios
        .get("/market/v1/orders", {
          params: {
            ticker,
            status,
            page: pageParam,
            page_size,
            order_by,
            dir,
          },
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: Response) => {
      if (pageRef.current * page_size >= lastPage.total) {
        return undefined;
      } else {
        return pageRef.current + 1;
      }
    },
    refetchInterval,
    enabled,
  });
  return result;
};
const useGetOrders = ({
  ticker,
  status,
  order_by = "unit_price",
  dir = "asc",
  enabled,
  page = 1,
  page_size = 30,
  refetchInterval,
}: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_ORDERS, ticker, page, status, order_by, dir],
    queryFn: () => {
      return axios
        .get("/market/v1/orders", {
          params: {
            ticker,
            status,
            page,
            page_size,
            order_by,
            dir,
          },
          paramsSerializer: {
            indexes: null,
          },
        })
        .then((res) => res.data);
    },
    refetchInterval,
    enabled,
  });
  return result;
};
export { useGetOrders, useInfiniteGetOrders };
