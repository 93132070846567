import { ButtonHTMLAttributes, ReactNode } from "react";
import { Loading } from "@/components";

interface IProps {
  className?: string;
  children: ReactNode;
  checked?: boolean;
  border?: boolean;
  loading?: boolean;
}
const Button = ({
  className,
  border,
  children,
  loading,
  ...props
}: IProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`px-3 py-2 chill text-sm h-8 rounded-md ${
        border
          ? `text-[#AAFF5D] border-[#AAFF5D] border ${
              loading || props.disabled
                ? ""
                : "hover:bg-[#AAFF5D] hover:text-black"
            }`
          : `bg-[#AAFF5D] text-black ${loading || props.disabled ? "" : "hover:opacity-75"}`
      }  ${loading ? "disabled" : ""} whitespace-nowrap  ${className} ${
        props.disabled ? "disabled opacity-30" : ""
      }`}
      style={{
        lineHeight: 0,
      }}
      {...props}
      onClick={(e) => {
        if (!loading) {
          props.onClick?.(e);
        }
      }}
    >
      {loading ? <Loading /> : children}
    </button>
  );
};

export default Button;
