import Image from "next/image";
import { rarrowIcon, successIcon } from "@/assets";

interface IProps {
  current: number;
  items: {
    title: string;
    description: string;
  }[];
}
const Step = ({ current = 0, items }: IProps) => {
  return (
    <div className="mb-6 flex justify-center">
      {items.map((item, index) => (
        <div key={item.title} className="flex">
          <div className="flex items-center">
            <div
              className={`w-8 h-8 p-[2px] ${index > current ? "border-[#494948]" : "border-[#71A443]"} border rounded-full mr-2`}
            >
              <div
                className={`w-full h-full flex justify-center items-center ${index === current ? "bg-[#AAFF5D] text-[#01010B]" : index > current ? "bg-[#494948] text-[#F0ECE6]" : "bg-[#2B3422] text-[#F0ECE6]"} rounded-full text-lg font-bold`}
              >
                {current <= index ? (
                  index + 1
                ) : (
                  <Image width={14} src={successIcon} alt="" />
                )}
              </div>
            </div>
            <div>
              <div
                className={`${index === current ? "text-[#F0ECE6]" : "text-[#9B9995]"} text-sm font-bold`}
              >
                {item.title}
              </div>
              <div
                className={`${index === current ? "text-[#AAFF5D]" : "text-[#71A443]"} text-xs`}
              >
                {item.description}
              </div>
            </div>
          </div>
          {index === items.length - 1 ? null : (
            <div className="w-[48px] relative mx-4">
              <Image fill src={rarrowIcon} alt="" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Step;

{
  /* <div className="flex items-center">
        <div className="w-8 h-8 p-[2px] border-[#71A443] border rounded-full mr-2">
          <div className="w-full h-full flex justify-center items-center bg-[#2B3422] rounded-full">
            <Image width={14} src={successIcon} alt="" />
          </div>
        </div>
        <div>
          <div>Unibit Market</div>
          <div>Buy $234.22</div>
        </div>
      </div>
      <div className="w-[48px] relative">
        <Image fill src={rarrowIcon} alt="" />
      </div>
      <div className="flex items-center">
        <div className="w-8 h-8 p-[2px] border-[#71A443] border rounded-full mr-2">
          <div className="w-full h-full flex justify-center items-center bg-[#AAFF5D] text-[#01010B] rounded-full text-lg font-bold">
            2
          </div>
        </div>
        <div>
          <div>Unibit Market</div>
          <div>Buy $234.22</div>
        </div>
      </div> */
}
