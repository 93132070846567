import { useQuery } from "@tanstack/react-query";
import { RQ_ADDRESS_FUA } from "../key";
import axios from "axios";
interface Response {
  addresses: { V1: string; V2: string }[];
}
interface IProps {
  type: "listing" | "buy";
  address: string;
  enabled: boolean;
}
const useAddressFua = ({ type, address, enabled }: IProps) => {
  const result = useQuery<Response>({
    queryKey: [RQ_ADDRESS_FUA, type, address],
    queryFn: () =>
      axios
        .get(`/market/v1/addresses/${address}/fua/${type}`)
        .then((res) => res.data),
    enabled,
  });
  return result;
};
export default useAddressFua;
