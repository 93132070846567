export const RQ_RECOMMENDED = "_RQ_recommended";
export const RQ_COINSLIST = "_RQ_coinslist";
export const RQ_COINSINFO = "_RQ_coinsinfo";
export const RQ_USDPRICE = "_RQ_usd_price";
export const RQ_TRADINGS = "_RQ_tradings";
export const RQ_ADDRESSTOKENS = "_RQ_address_tokens";
export const RQ_ADDRESSBALANCES = "_RQ_address_balances";
export const RQ_LISTABLE = "_RQ_listable";
export const RQ_COINSSTATUS = "_RQ_coins_status";
export const RQ_ORDERS = "_RQ_orders";
export const RQ_BUY_LISTINGS = "_RQ_buy_listings";
export const RQ_ORDERS_ADDRESS = "_RQ_orders_address";
export const RQ_ADDRESS_TICKERS = "_RQ_address_tickers";
export const RQ_ADDRESS_FUA = "_RQ_address_fua";
export const RQ_ORDERS_FEES = "_RQ_orders_fees";
