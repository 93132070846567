import Image from "next/image";
import {
  quarkIcon,
  atomIcon,
  atomiIcon,
  atomicalsIcon,
  avmIcon,
  dogeIcon,
  dotsIcon,
  dragonIcon,
  electronIcon,
  icalsIcon,
  infinityIcon,
  neutronIcon,
  pepeIcon,
  pizzaIcon,
  realmIcon,
} from "@/assets";

const icons = {
  quark: quarkIcon,
  atom: atomIcon,
  atomi: atomiIcon,
  atomicals: atomicalsIcon,
  avm: avmIcon,
  doge: dogeIcon,
  dots: dotsIcon,
  dragon: dragonIcon,
  electron: electronIcon,
  icals: icalsIcon,
  infinity: infinityIcon,
  neutron: neutronIcon,
  pepe: pepeIcon,
  pizza: pizzaIcon,
  realm: realmIcon,
};
interface TokenIconProps {
  token: string;
  className?: string;
}
const TokenIcon = ({ token, className }: TokenIconProps) => {
  return (
    <div
      className={`${className} rounded-full relative text-lg flex-none ${!(token in icons) ? "bg-[#AAFF5D] opacity-60" : ""}`}
    >
      {token in icons ? (
        <Image
          src={icons[token as keyof typeof icons]}
          className="w-full -h-full rounded-full"
          alt=""
        />
      ) : (
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[#595959] chill">
          {token.toLocaleUpperCase().slice(0, 1)}
        </span>
      )}
    </div>
  );
};
export default TokenIcon;
