import useWalletInstance from "@/hooks/wallet/useWalletInstance";
import { envNetwork } from "@/plugins/config";
import { useEffect, useState } from "react";
import useNetwork from "./useNetWork";
// import Cookies from "js-cookie";

const useGetAccounts = () => {
  const { wallet } = useWalletInstance();
  const unisatNetwork = useNetwork();
  const [accounts, setAccounts] = useState<string[]>([]);
  const getAccounts = async () => {
    if (wallet) {
      if (envNetwork === unisatNetwork) {
        const result = await wallet.getAccounts();
        setAccounts(result);
      } else {
        setAccounts([]);
      }
    }
  };
  const handlerAccounts = (accounts_result: Array<string>) => {
    setAccounts(accounts_result);
  };
  useEffect(() => {
    if (!unisatNetwork) return;
    getAccounts();
    wallet?.on("accountsChanged", handlerAccounts);
    return () => {
      wallet?.removeListener("accountsChanged", handlerAccounts);
    };
  }, [wallet, unisatNetwork]);
  return {
    accounts,
    setAccounts,
  };
};
export default useGetAccounts;
