interface IProps {
  value: number;
  max?: number;
  disabled?: boolean;
  onChange?: (v: number) => void;
}
const Range = ({ value, disabled, max = 1000, onChange }: IProps) => {
  return (
    <div className="relative w-full h-4 overflow-hidden">
      <div
        className="bg-[#AAFF5D] absolute top-[4px] h-2 rounded-xl rounded-r-none cursor-pointer"
        style={{
          width: `${value ? (value / max) * 98 : 0}%`,
        }}
      />
      <div className="w-full h-2 bg-[rgba(207,225,255,0.1)] block absolute left-0 top-[4px] -z-[1] rounded-xl" />
      <input
        type="range"
        min="0"
        max={max || 0}
        value={value}
        disabled={disabled}
        className="w-full absolute top-0 z-20 cursor-pointer"
        onChange={(e) => {
          onChange?.(Number(e.target.value));
        }}
      />
    </div>
  );
};
export default Range;
